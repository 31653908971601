/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .image-thumbnail .premium-text-conatiner {
  flex-direction: row-reverse; }

.ltr .default-image {
  background-image: url("~app/resources/assets/thumbnail/defaultthumb.png"); }

.rtl .default-image {
  background-image: url("~app/resources/assets/thumbnail/defaultthumb.png"); }

.image-thumbnail {
  position: relative;
  border-top-width: 0;
  border-bottom-width: 0;
  color: #fff;
  background-color: transparent;
  transition: all 0.3s ease;
  cursor: pointer; }
  .image-thumbnail .plan-icon {
    position: absolute;
    width: 10%;
    max-width: 22px;
    top: 6px;
    left: 6px;
    z-index: 2; }
  .image-thumbnail .carousel-image :last-child {
    z-index: -1; }
  .image-thumbnail .progressbar-container {
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: #000; }
    .image-thumbnail .progressbar-container .progressbar-progress {
      height: 5px; }
  .image-thumbnail.selected {
    transform: scale(1.04); }
    .image-thumbnail.selected .down-arrow .icon {
      transform: rotate(180deg); }
    .image-thumbnail.selected .carousel-bottom-overlay {
      display: flex; }
    .image-thumbnail.selected .down-arrow {
      display: block; }
  .image-thumbnail .image {
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none; }
  .image-thumbnail .carousel-bottom-overlay {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 5px;
    z-index: 2;
    align-items: center; }
    .image-thumbnail .carousel-bottom-overlay span {
      font-size: 0.713em;
      display: inline-block; }
      .image-thumbnail .carousel-bottom-overlay span.ic-star {
        width: 17px;
        height: 16px;
        background: url("~app/resources/assets/thumbnail/ic-star.svg");
        margin-right: 5px;
        background-repeat: no-repeat;
        background-size: contain; }
  .image-thumbnail .desc-containar {
    height: 80px;
    overflow: hidden; }
    .image-thumbnail .desc-containar p {
      height: inherit; }
  .image-thumbnail .on-image-desc {
    position: absolute;
    bottom: 10px;
    left: 40px;
    font-weight: bold;
    z-index: 2;
    display: flex; }
  .image-thumbnail .down-arrow {
    display: none;
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    margin: 0;
    font-weight: bold;
    z-index: 2; }
    .image-thumbnail .down-arrow:hover {
      background-color: transparent; }
    .image-thumbnail .down-arrow .icon {
      width: 35px;
      transition: transform 0.3s;
      top: 0; }
  .image-thumbnail .play-button {
    display: none;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    height: 30%;
    width: 30%;
    transition: opacity 0.3s ease;
    opacity: 1;
    padding: 0;
    margin: 0; }
    .image-thumbnail .play-button:hover {
      background-color: transparent; }
    .image-thumbnail .play-button .icon {
      width: 100% !important;
      height: 100% !important; }
  .image-thumbnail .overlay-color {
    width: 100%;
    height: 20%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
    z-index: 1; }
  .image-thumbnail .premium-text-conatiner {
    display: flex;
    flex-direction: row; }
    .image-thumbnail .premium-text-conatiner .premium-text {
      position: absolute;
      top: 0;
      background-color: #ff740f;
      font-size: 14px;
      padding: 0 5px;
      opacity: 0.9; }
  .image-thumbnail.thumnail-hover:hover {
    transform: scale(1.04, 1.04);
    z-index: 1; }
    .image-thumbnail.thumnail-hover:hover .play-button-hover {
      display: block; }
    .image-thumbnail.thumnail-hover:hover .trailor-play-btn {
      transform: translate(-50%, -90%);
      -webkit-transform: translate(-50%, -90%); }
    .image-thumbnail.thumnail-hover:hover .carousel-bottom-overlay {
      display: flex;
      flex-direction: row; }
    .image-thumbnail.thumnail-hover:hover .down-arrow {
      display: block; }
    .image-thumbnail.thumnail-hover:hover .overlay-color {
      opacity: 1; }
  .image-thumbnail.play-button-image-hover:hover .play-button-hover {
    display: block; }

/*Device width pixel lesser than 745*/
@media only screen and (max-width: 745px) {
  .image-thumbnail .carousel-bottom-overlay,
  .image-thumbnail .on-image-desc {
    top: calc(100% - 130px); }
  .image-thumbnail .on-image-desc {
    left: 35px; }
  .image-thumbnail .down-arrow {
    top: 160px;
    left: 35%; }
  .image-thumbnail.thumnail-hover:hover {
    transform: none; }
    .image-thumbnail.thumnail-hover:hover .carousel-bottom-overlay {
      display: none; }
    .image-thumbnail.thumnail-hover:hover .down-arrow {
      display: none; }
    .image-thumbnail.thumnail-hover:hover .overlay-color {
      opacity: 0; }
  .image-thumbnail .play-button {
    display: inline-block; } }

/*Device width pixel in between 360-450*/
@media only screen and (max-width: 450px) {
  .image-thumbnail .carousel-bottom-overlay,
  .image-thumbnail .on-image-desc {
    top: calc(100% - 130px); }
  .image-thumbnail .on-image-desc {
    left: 25px; }
  .image-thumbnail .down-arrow {
    top: 160px;
    left: 35%; } }

.app-body[dir="rtl"] .watching_details .carousel-bottom-overlay {
  bottom: 0px;
  right: 0px; }

.Continue_watching a {
  text-decoration: none; }

.Continue_watching .image-thumbnail .progressbar-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: transparent;
  margin-top: -5px; }
  .Continue_watching .image-thumbnail .progressbar-container .progressbar-progress {
    border-radius: 2px;
    margin-left: 9px;
    margin-right: 9px; }

.Continue_watching .carousel-bottom-overlay {
  bottom: 0px;
  left: 0px; }

.Continue_watching .carousel-image.image-container {
  position: relative; }

.Continue_watching .carousel-image.image-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: transparent linear-gradient(359deg, #292929 0%, #70707000 100%); }

.Continue_watching .image-thumbnail .play-button {
  margin: -50px 0px; }

.Continue_watching .image-thumbnail.thumnail-hover:hover {
  -webkit-transform: inherit;
  transform: inherit;
  z-index: 1; }

.Continue_watching .watching_details {
  background-color: #292929;
  font-family: 'changa-Regular';
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  margin-top: -45px;
  z-index: 2; }
  .Continue_watching .watching_details .btn:hover {
    background-color: transparent; }
  .Continue_watching .watching_details:hover .carousel-bottom-overlay {
    display: none; }
  .Continue_watching .watching_details .watching_title {
    min-height: 48px;
    color: white;
    position: relative;
    bottom: 0;
    font-size: 18px;
    display: block;
    line-height: 20px;
    margin-bottom: 10px;
    word-wrap: break-word;
    padding-left: 9px;
    padding-right: 9px;
    word-wrap: break-word; }
    @media only screen and (min-width: 1400px) {
      .Continue_watching .watching_details .watching_title {
        font-size: 18px;
        line-height: 18px;
        font-weight: 500; } }
    @media only screen and (min-width: 1024px) {
      .Continue_watching .watching_details .watching_title {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500; } }
    @media only screen and (max-width: 768px) {
      .Continue_watching .watching_details .watching_title {
        font-size: 12px;
        font-weight: 500;
        min-height: 35px;
        line-height: 18px;
        font-family: 'changa'; } }
  .Continue_watching .watching_details p {
    color: #757575;
    font-size: 16px;
    padding-left: 9px;
    padding-right: 9px;
    margin-bottom: 10px;
    min-height: 30px; }
    @media only screen and (max-width: 768px) {
      .Continue_watching .watching_details p {
        font-size: 11px; } }
    @media only screen and (min-width: 1400px) {
      .Continue_watching .watching_details p {
        font-size: 16px; } }
  .Continue_watching .watching_details .watching_actions {
    display: flex;
    justify-content: space-between;
    margin-right: 3px;
    margin-left: 3px; }
    .Continue_watching .watching_details .watching_actions .btn {
      margin: 3px 3px !important; }
  .Continue_watching .watching_details .icon {
    height: 33px;
    width: 33px; }
    @media only screen and (max-width: 1024px) {
      .Continue_watching .watching_details .icon {
        height: 24px;
        width: 24px; } }
    @media only screen and (max-width: 768px) {
      .Continue_watching .watching_details .icon {
        height: 24px;
        width: 24px; } }

.addItem-btn {
  background-image: url(../../../resources/assets/add.svg);
  width: 33px;
  height: 33px; }
  .addItem-btn:hover {
    background-image: url(../../../resources/assets/playlist_Plus_hover.svg);
    width: 33px;
    height: 33px;
    background-size: 33px; }
    @media only screen and (max-width: 768px) {
      .addItem-btn:hover {
        background-image: url(../../../resources/assets/add.svg); } }

.MinusItem-btn {
  background-image: url(../../../resources/assets/removeItem.svg);
  width: 33px;
  height: 33px; }
  .MinusItem-btn:hover {
    background-image: url(../../../resources/assets/removeItemHover.svg);
    width: 33px;
    height: 33px;
    background-size: 33px; }
    @media only screen and (max-width: 768px) {
      .MinusItem-btn:hover {
        background-image: url(../../../resources/assets/removeItem.svg); } }

.PlayItem-btn {
  background-image: url(../../../resources/assets/playsvg.svg);
  width: 33px;
  height: 33px; }
  .PlayItem-btn:hover {
    background-image: url(../../../resources/assets/playsvgHover.svg);
    width: 33px;
    height: 33px;
    background-size: 33px; }
    @media only screen and (max-width: 768px) {
      .PlayItem-btn:hover {
        background-image: url(../../../resources/assets/playsvg.svg); } }

.RemoveItem-btn {
  background-image: url(../../../resources/assets/remove.svg);
  width: 33px;
  height: 33px; }
  .RemoveItem-btn:hover {
    background-image: url(../../../resources/assets/removeHover.svg);
    width: 33px;
    height: 33px;
    background-size: 33px; }
    @media only screen and (max-width: 768px) {
      .RemoveItem-btn:hover {
        background-image: url(../../../resources/assets/remove.svg); } }

.trailers-image-container .pos-relative {
  position: relative; }

.trailers-image-container .on-image-desc {
  top: inherit !important;
  bottom: 10px !important; }

.trailers-image-container .desc-containar {
  height: auto !important;
  position: relative;
  top: -5px; }
  .trailers-image-container .desc-containar .description {
    line-height: 15px; }
  .trailers-image-container .desc-containar p {
    margin: 0 !important; }

.Continue_watching .swiper-slide:hover {
  transform: scale(1.0333) !important;
  transition: all .3s ease-in-out; }
  @media only screen and (max-width: 767px) {
    .Continue_watching .swiper-slide:hover {
      transform: scale(1) !important; } }
