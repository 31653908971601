/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

.home-content {
  //background: black;
  .carousel-item {
    .desription {
      white-space: normal;
    }
  }
  .bucket-item-container {
    padding: 0.05px; //to prevent collapsing margin
    &:last-child {
      // margin-bottom: 50px;
    }
  }
}
/*Device width pixel lesser than 768*/
@media only screen and (max-width: 768px) {
  .scroll-menu-arrow {
    display: none;
  }
}

/*Device width pixel in between 360-450*/
@media only screen and (max-width: 450px) {
}
