/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

 @import "app/resources/styles/variables.scss";

 .app-body[dir="rtl"] {
   .header {
     .langButton {
       margin: 0px 0px 0 8px;
       .icon {
         margin: 0;
       }
     }
 
     .lanchange-btn {
       margin: 0px 0px 0 15px;
       .icon {
         margin: 0;
       }
       span {
        width: 100%;
        display: flex;
        align-items: center;
       }
     }
     
     .vertical-separator {
       margin: -3px 9px;
     }
   }
 }
 
 .langButton {
   color: transparent;
   display: inline-block;
   background: transparent;
   padding: 0;
   border-radius: 50%;
   font-size: $language-button-font-size;
   border: 2px solid;
   margin: 5px 11px;
   height: 44px;
   width: 44px;
   overflow: hidden;
   // &:hover {
   //   background-color: transparent;
   // }
   .icon {
     width: 100%;
     height: 100%;
     margin: 0;
     top: 0;
   }
 }
 .language_buttons{
  width: 15px;
  height: 14px;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #EC1C24 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #EE7623 0%, #EC1C24 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 7px #FDAA0B;
  opacity: 1;
  border-radius: 17px;
  opacity: 1;
  position: inherit;
  // top: -63%;
  /* top: -1em; */
  /* left: -35px; */
  // right: -1.5em;
  @media(max-width:767px){
    display: none;
  }
   }
 .language_buttonsAr {
   width: 15px;
   height: 14px;
   background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #EC1C24 100%) 0% 0% no-repeat padding-box;
   background: transparent linear-gradient(180deg, #EE7623 0%, #EC1C24 100%) 0% 0% no-repeat padding-box;
   box-shadow: inset 0px 0px 7px #FDAA0B;
   opacity: 1;
   border-radius: 17px;
   opacity: 1;
   position: inherit;
  //  top: -65%;
  //  left: -1.5em;
  @media(max-width:767px){
    display: none;
  }
 
 }
   .btnlantext {
     color: #FFFFFF;
     position: inherit;
     margin-right: 5px;
    //  right: 12px;
    //  left: unset;
    //  top: 0.25rem;
   }
   .btnlantextAr{
    color: #fff;
    position: inherit;
    // left: 15px;
    // right: unset;
    margin-left: 5px;

    @media(max-width:767px){
      padding: 11px;
      margin-top: -3px;
  }
    // margin-right: -25px;
    // margin-left: unset;
   }

   .lanchange-btn {
    margin: 0px 0px 0 15px;
    .icon {
      margin: 0;
    }
    span {
     width: 100%;
     display: flex;
     align-items: center;
    }
  }