/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.phone-input-container {
  width: 100%; }
  .phone-input-container .phone-input {
    height: 53px;
    width: 100%;
    display: flex;
    color: white; }
    .phone-input-container .phone-input .country-code-container {
      width: 65px;
      display: flex;
      cursor: pointer; }
      .phone-input-container .phone-input .country-code-container .country-code-input {
        line-height: 53px;
        min-width: 40px; }
    .phone-input-container .phone-input .down-arrow {
      width: 25px;
      height: 53px;
      background: url("~app/resources/assets/login/phone-down-arrow.svg") center no-repeat;
      border: none;
      z-index: 1;
      transform: scale(0.7);
      top: 0;
      margin: 0; }
    @media screen and (max-width: 350px) {
      .phone-input-container .phone-input .number-input {
        width: 100%; } }
  .phone-input-container .country-dropdown {
    list-style: none;
    position: absolute;
    z-index: 2;
    padding: 0;
    margin: 0;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: #fff;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll; }
    .phone-input-container .country-dropdown .country-item {
      display: block;
      color: black;
      padding: 7px 10px;
      border-bottom: 1px solid #bbb;
      cursor: pointer; }
      .phone-input-container .country-dropdown .country-item .country-code {
        display: inline-block;
        width: 60px; }
      .phone-input-container .country-dropdown .country-item .country-name {
        display: inline-block; }
