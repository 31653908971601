/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
.btn {
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  color: white;
  padding: 6px 5px;
  border: none;
  margin: 0 5px;
  font-family: inherit;
  /* Darker background on mouse-over */
  &:hover {
    background-color: rgb(255, 121, 0);
  }

  .icon {
    width: 20px;
    // height: 20px;
    position: relative;
    top: 3px;
  }

  &[disabled] {
    color: rgba(0,0,0,0.38);
    cursor: default;
  }
}
