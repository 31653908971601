/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

.input {
  font-size: 20px;
  background-color: transparent;
  color: white;
  padding: 6px 5px;
  border: none;
  margin: 0 5px;
  font-family: inherit;
  caret-color: white;
}
