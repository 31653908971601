/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.rtl .pound_english {
  display: none !important; }

.rtl .pound_arabic {
  display: block !important; }

.ltr .pound_english {
  display: block !important; }

.ltr .pound_arabic {
  display: none !important; }

.app-body[dir="rtl"] .enter-mobile-container .enter-mobile2 {
  text-align: right; }

.app-body[dir="rtl"] .enter-mobile-container .input-container .mobile-input-container {
  flex-direction: row-reverse; }
  .app-body[dir="rtl"] .enter-mobile-container .input-container .mobile-input-container .input {
    margin-right: 0;
    margin-left: 3%; }
  .app-body[dir="rtl"] .enter-mobile-container .input-container .mobile-input-container .country-code {
    text-align: left; }

.app-body[dir="rtl"] .enter-mobile-container .conditions-container {
  text-align: right; }

.enter-mobile-container {
  width: 100%;
  min-height: 76vh;
  padding: 0 10%;
  text-align: center;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "changa"; }
  .enter-mobile-container .margin-collapse {
    height: 1px; }
  .enter-mobile-container .mobile-number input {
    width: 98%; }
  .enter-mobile-container .error-message {
    color: #bd0000;
    font-style: normal;
    padding: 4px;
    margin-top: 0;
    margin-bottom: 6px;
    font-weight: 400;
    height: 17px;
    font-size: 12px;
    position: relative;
    align-items: baseline;
    text-align: left;
    width: 100%; }
  .enter-mobile-container .enjoy_weyyak_experience {
    margin-top: 70px;
    font-size: 20px;
    line-height: 21px; }
    @media (max-width: 745px) {
      .enter-mobile-container .enjoy_weyyak_experience {
        font-size: 16px; } }
  .enter-mobile-container .enter-mobile2 {
    margin-top: 60px;
    padding: 0 10px;
    line-height: 21px;
    font-size: 18px;
    text-align: left;
    width: 100%; }
    @media (max-width: 745px) {
      .enter-mobile-container .enter-mobile2 {
        font-size: 12px; } }
  .enter-mobile-container .input-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    font-size: 18px; }
    @media (max-width: 745px) {
      .enter-mobile-container .input-container {
        font-size: 16px; } }
    .enter-mobile-container .input-container .mobile-input-container {
      display: flex;
      width: 75%; }
      .enter-mobile-container .input-container .mobile-input-container .input {
        height: 54px;
        border-radius: 5px;
        border: 2px solid #4a4a4a;
        margin-right: 3%;
        font-size: 18px; }
        @media (max-width: 745px) {
          .enter-mobile-container .input-container .mobile-input-container .input {
            height: 42px;
            font-size: 16px; } }
      .enter-mobile-container .input-container .mobile-input-container .country-code {
        width: 18%;
        min-width: 45px;
        text-align: right; }
      .enter-mobile-container .input-container .mobile-input-container .mobile-number {
        min-width: 0;
        flex-grow: 1;
        float: right; }
    .enter-mobile-container .input-container .send-button {
      width: 23%;
      height: 54px;
      border-radius: 5px;
      background-color: #ff6d02;
      min-width: 75px; }
      @media (max-width: 745px) {
        .enter-mobile-container .input-container .send-button {
          height: 42px; } }
      .enter-mobile-container .input-container .send-button span {
        font-size: 18px; }
        @media (max-width: 745px) {
          .enter-mobile-container .input-container .send-button span {
            font-size: 16px; } }
  .enter-mobile-container .exit {
    width: 32px;
    height: 22px;
    font-size: 18px;
    text-decoration: underline;
    margin-top: 40px;
    cursor: pointer; }
    @media (max-width: 745px) {
      .enter-mobile-container .exit {
        font-size: 16px; } }
  .enter-mobile-container .exit-etisalat {
    width: 32px;
    height: 22px;
    font-size: 18px;
    text-decoration: underline;
    margin-top: -65px;
    cursor: pointer; }
    @media (max-width: 745px) {
      .enter-mobile-container .exit-etisalat {
        font-size: 16px; } }
  .enter-mobile-container .conditions-container {
    width: 100%;
    margin: 40px 0 80px;
    color: #555555;
    text-align: left;
    font-size: 15px;
    line-height: 23px; }
    @media (max-width: 745px) {
      .enter-mobile-container .conditions-container {
        font-size: 12px;
        line-height: 16px; } }
    .enter-mobile-container .conditions-container .by-clicking,
    .enter-mobile-container .conditions-container .free-for {
      font-weight: 700; }
    .enter-mobile-container .conditions-container .link {
      color: #555555; }
  .enter-mobile-container .etisalat-container {
    width: 100%;
    margin: 40px 0 80px;
    color: #555555;
    text-align: left;
    font-size: 15px;
    line-height: 23px; }
    @media (max-width: 745px) {
      .enter-mobile-container .etisalat-container {
        font-size: 12px;
        line-height: 16px; } }
    .enter-mobile-container .etisalat-container .by-clicking,
    .enter-mobile-container .etisalat-container .free-for-en {
      font-weight: 700;
      margin-top: -44px;
      margin-left: -505px; }
    .enter-mobile-container .etisalat-container .free-for-ar {
      font-weight: 700;
      margin-top: -44px;
      margin-right: -505px;
      position: relative;
      left: 117px; }
    .enter-mobile-container .etisalat-container .link {
      color: #555555; }
  .enter-mobile-container .free_for_et {
    font-weight: 700;
    color: #555555;
    position: relative;
    top: 63px; }

@media (max-width: 767px) {
  .enter-mobile-container .etisalat-container .free-for-en {
    font-weight: 700;
    margin-top: -44px;
    margin-left: 0; }
  .enter-mobile-container .etisalat-container .free-for-ar {
    font-weight: 700;
    margin-top: -44px;
    position: relative;
    margin-right: 0px;
    left: 0;
    text-align: right; }
  .enter-mobile-container .input-container .send-button {
    height: 40px;
    line-height: 27px; }
  .enter-mobile-container .input-container .send-button span {
    font-size: 14px; }
  .enter-mobile-container .exit-etisalat {
    width: 32px;
    height: 22px;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 auto;
    margin-top: -65px; } }
