/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
s {
  color: white;
  display: inline-block;
  text-decoration: none;
  position: relative; }

s:after {
  content: '';
  display: block;
  color: red;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  border-bottom: 3px solid;
  transform: rotate(-7deg);
  -ms-transform: rotate(-7deg);
  -moz-transform: rotate(-7deg);
  -webkit-transform: rotate(-7deg);
  -o-transform: rotate(-7deg); }
  @media only screen and (max-width: 745px) {
    s:after {
      left: 2px; } }

.rtl .pound_english {
  display: none !important; }

.rtl .pound_arabic {
  display: block !important; }

.ltr .pound_english {
  display: block !important; }

.ltr .pound_arabic {
  display: none !important; }

.app-body[dir="rtl"] .plans-container .cards-container .adyen-container .plans-header .text,
.app-body[dir="rtl"] .plans-container .cards-container .coupon-container .plans-header .text,
.app-body[dir="rtl"] .plans-container .cards-container .etisalat-container .plans-header .text,
.app-body[dir="rtl"] .plans-container .cards-container .zain-container .plans-header .text,
.app-body[dir="rtl"] .plans-container .cards-container .jawwal-container .plans-header .text,
.app-body[dir="rtl"] .plans-container .cards-container .ooredoo-container .plans-header .text,
.app-body[dir="rtl"] .plans-container .cards-container .tpay-container .plans-header .text {
  float: right;
  margin-left: 10px;
  margin-right: 0; }

.app-body[dir="rtl"] .plans-container .cards-container .adyen-container .plans-header .arrow,
.app-body[dir="rtl"] .plans-container .cards-container .coupon-container .plans-header .arrow,
.app-body[dir="rtl"] .plans-container .cards-container .etisalat-container .plans-header .arrow,
.app-body[dir="rtl"] .plans-container .cards-container .zain-container .plans-header .arrow,
.app-body[dir="rtl"] .plans-container .cards-container .jawwal-container .plans-header .arrow,
.app-body[dir="rtl"] .plans-container .cards-container .ooredoo-container .plans-header .arrow,
.app-body[dir="rtl"] .plans-container .cards-container .tpay-container .plans-header .arrow {
  float: left;
  transform: scaleX(-1); }

.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row .plan-name,
.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row .radio-div, .app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row .radio-div-selected {
  float: right; }

.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row .plan-name {
  margin-left: 0;
  margin-right: 10px; }
  @media only screen and (max-width: 745px) {
    .app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row .plan-name {
      margin-left: 0;
      margin-right: 5px;
      font-size: 11px; } }

.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row .trial_period {
  float: left; }

.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row-yearly .plan-name,
.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row-yearly .radio-div, .app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row-yearly .radio-div-selected {
  float: right; }

.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row-yearly .plan-name {
  margin-left: 0;
  margin-right: 10px; }
  @media only screen and (max-width: 745px) {
    .app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row-yearly .plan-name {
      margin-left: 0;
      margin-right: 5px; } }

.app-body[dir="rtl"] .plans-container .cards-container .plans .plan-row-yearly .trial_period {
  float: left; }

.app-body[dir="rtl"] .plans-container .cards-container .coupon-container .coupon-error {
  text-align: right; }

.app-body[dir="rtl"] .right_svod {
  flex: inherit !important; }
  .app-body[dir="rtl"] .right_svod .displblock_ar {
    display: block;
    float: right;
    margin-top: 15px; }
  .app-body[dir="rtl"] .right_svod .price_arebic {
    font-size: 25px !important;
    float: left; }

.app-body[dir="rtl"] .explore_btn {
  margin-right: 100px;
  margin-top: 0px !important; }

.plans-container {
  width: 100%;
  min-height: calc(100vh - 245px);
  background-color: #0F1822;
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  font-family: "changa";
  margin-top: 18px; }
  @media only screen and (max-width: 745px) {
    .plans-container {
      min-height: calc(100vh - 235px); } }
  .plans-container .plans-text-container {
    width: 700px;
    margin-top: 70px;
    font-size: 20px;
    line-height: 29px; }
    @media only screen and (max-width: 745px) {
      .plans-container .plans-text-container {
        width: 90%;
        font-size: 18px; } }
  .plans-container .icons-list {
    margin-top: 130px;
    display: flex;
    flex-direction: row;
    width: 65%;
    justify-content: center;
    margin-bottom: 40px;
    font-family: "changa"; }
    .plans-container .icons-list .container {
      width: 25%; }
    .plans-container .icons-list .icon {
      height: 39px;
      margin: 0;
      background-position: center;
      background-repeat: no-repeat; }
    .plans-container .icons-list .text {
      margin-top: 15px;
      font-size: 16px;
      color: grey; }
      @media only screen and (max-width: 745px) {
        .plans-container .icons-list .text {
          font-size: 14px; } }
    .plans-container .icons-list .no-ads .icon {
      background-image: url("~app/resources/assets/subscribe/no_ads1.svg"); }
    .plans-container .icons-list .smart-tv .icon {
      background-image: url("~app/resources/assets/subscribe/smarttv1.png"); }
    .plans-container .icons-list .exclusive-content .icon {
      background-image: url("~app/resources/assets/subscribe/exclusive_content1.svg"); }
    .plans-container .icons-list .downloads .icon {
      background-image: url("~app/resources/assets/subscribe/downloads1.svg"); }
    .plans-container .icons-list .hd-content .icon {
      background-image: url("~app/resources/assets/subscribe/hd_content1.svg"); }
  .plans-container .plans_content {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    font-family: "changa"; }
    .plans-container .plans_content .plans_title {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 24px; }
    .plans-container .plans_content .cards-container {
      width: 700px;
      margin: 20px 0 15px 0; }
    .plans-container .plans_content .brder {
      padding: 8px 0;
      font-size: 14px;
      border-top: 1px solid #414141;
      border-bottom: 1px solid #414141; }
    .plans-container .plans_content .svod_text {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2px; }
    .plans-container .plans_content .for_arabic {
      display: none !important;
      font-size: 25px !important;
      letter-spacing: 1px; }
    .plans-container .plans_content .for_english {
      display: inline-block !important; }
    .plans-container .plans_content .svod_ul_ar .left_svod {
      text-align: right !important; }
      .plans-container .plans_content .svod_ul_ar .left_svod .mo_icon {
        margin-right: 0px !important;
        margin-left: 8px !important; }
        .plans-container .plans_content .svod_ul_ar .left_svod .mo_icon img {
          margin-right: 0 !important;
          margin-left: 4px !important; }
    .plans-container .plans_content .svod_ul_ar .right_svod {
      flex: inherit !important; }
      .plans-container .plans_content .svod_ul_ar .right_svod .displblock_ar {
        display: block;
        margin-bottom: 4px; }
    .plans-container .plans_content .svod_ul_ar .brder {
      text-align: right; }
    .plans-container .plans_content .svod_ul_ar .for_arabic {
      display: inline-block !important; }
    .plans-container .plans_content .svod_ul_ar .for_english {
      display: none !important; }
    .plans-container .plans_content .svod_ul {
      margin: 0;
      padding: 0;
      margin-bottom: 30px; }
      .plans-container .plans_content .svod_ul li {
        list-style: none;
        background-color: #1f1f1f;
        margin-bottom: 15px;
        border-radius: 8px;
        padding: 24px;
        text-align: left;
        position: relative; }
        .plans-container .plans_content .svod_ul li .li_flex {
          display: flex;
          width: 100%;
          justify-content: space-between; }
        .plans-container .plans_content .svod_ul li .left_svod {
          text-align: left; }
          .plans-container .plans_content .svod_ul li .left_svod .mo_icon {
            position: relative;
            top: 5px;
            margin-right: 8px; }
            .plans-container .plans_content .svod_ul li .left_svod .mo_icon img {
              margin-right: 5px; }
          .plans-container .plans_content .svod_ul li .left_svod .marT15 {
            margin-top: 15px;
            display: inline-block; }
        .plans-container .plans_content .svod_ul li .right_svod {
          flex: 1;
          text-align: right; }
          .plans-container .plans_content .svod_ul li .right_svod span {
            margin-left: 5px;
            font-size: 20px;
            font-weight: bold; }
            .plans-container .plans_content .svod_ul li .right_svod span:first-child {
              font-weight: normal;
              margin-right: 5px;
              font-size: 12px;
              margin-left: 0; }
            .plans-container .plans_content .svod_ul li .right_svod span:last-child {
              margin-left: 0; }
        .plans-container .plans_content .svod_ul li .explore_btn button {
          background-color: #ff740f;
          min-width: 53px;
          border-radius: 5px;
          font-size: 12px;
          text-transform: capitalize;
          border: 0;
          color: #ffffff;
          padding: 5px 10px; }
        .plans-container .plans_content .svod_ul li .cards-container {
          width: inherit; }
        .plans-container .plans_content .svod_ul li .arrow_down1 {
          border: 0;
          background-color: transparent;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 5px;
          margin: 0 50%;
          text-align: center; }
          .plans-container .plans_content .svod_ul li .arrow_down1 .arrow_down {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #ffffff;
            margin: 0 auto; }
          .plans-container .plans_content .svod_ul li .arrow_down1 .arrow_top {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #ffffff;
            margin: 0 auto; }
  .plans-container .cards-container {
    width: 700px;
    margin: 70px 0; }
    @media only screen and (max-width: 745px) {
      .plans-container .cards-container {
        width: 100% !important; } }
    .plans-container .cards-container .plans-header {
      cursor: pointer; }
      .plans-container .cards-container .plans-header.brdertpbtm {
        border-top: 1px solid #414141;
        border-bottom: 1px solid #414141;
        padding-top: 10px;
        padding-bottom: 10px; }
    .plans-container .cards-container .adyen-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141;
      clear: both; }
      .plans-container .cards-container .adyen-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .adyen-container .plans-header .text {
        height: 90px;
        line-height: 90px;
        margin-right: 10px;
        float: left;
        font-size: 14px; }
        @media only screen and (max-width: 745px) {
          .plans-container .cards-container .adyen-container .plans-header .text {
            font-size: 12px; } }
      .plans-container .cards-container .adyen-container .plans-header .image1 {
        width: 135px !important;
        max-width: 135px;
        height: 90px;
        background: url("~app/resources/assets/plans/card1.png") no-repeat center;
        margin-right: 4px; }
        @media only screen and (max-width: 745px) {
          .plans-container .cards-container .adyen-container .plans-header .image1 {
            background-size: 90%;
            width: 78px;
            height: 90px;
            max-width: 78px; } }
      .plans-container .cards-container .adyen-container .plans-header .image2 {
        width: 50px;
        max-width: 69px;
        height: 90px;
        background: url("~app/resources/assets/plans/card2.png") no-repeat center;
        float: right;
        margin-right: 4px; }
        @media only screen and (max-width: 745px) {
          .plans-container .cards-container .adyen-container .plans-header .image2 {
            background-size: 90%;
            width: 43px;
            max-width: 43px; } }
    .plans-container .cards-container .etisalat-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .etisalat-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .etisalat-container .plans-header .image1 {
        width: 133px;
        height: 90px;
        background: url("~app/resources/assets/plans/englishEtisalat.svg") no-repeat center;
        float: left; }
      .plans-container .cards-container .etisalat-container .plans-header .arEtisalat {
        width: 133px;
        height: 90px;
        background: url("~app/resources/assets/plans/arabicEtisalat.svg") no-repeat center;
        float: left; }
      .plans-container .cards-container .etisalat-container .plans-header .radio-div1 {
        display: inline-block;
        float: left;
        margin-top: 37px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #919191; }
    .plans-container .cards-container .zain-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .zain-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .zain-container .plans-header .image1 {
        width: 133px;
        height: 90px;
        background: url("~app/resources/assets/plans/tpay/zain.png") no-repeat center;
        float: left; }
    .plans-container .cards-container .jawwal-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .jawwal-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .jawwal-container .plans-header .image1 {
        width: 137px;
        height: 90px;
        background: url("~app/resources/assets/plans/jawwal.png") no-repeat center;
        float: left; }
    .plans-container .cards-container .ooredoo-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .ooredoo-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .ooredoo-container .plans-header .image1 {
        width: 133px;
        height: 90px;
        background: url("~app/resources/assets/plans/TimWeOoredoo.png") no-repeat center;
        float: left; }
    .plans-container .cards-container .telus-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .telus-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .telus-container .plans-header .image1 {
        width: 146px;
        height: 90px;
        background: url("~app/resources/assets/plans/telus.svg") no-repeat center;
        float: left; }
    .plans-container .cards-container .info-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .info-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .info-container .plans-header .image1 {
        width: 50px;
        height: 80px;
        background: url("~app/resources/assets/plans/infomedia/infomedia_ee.png") no-repeat center;
        float: left;
        background-size: 55px; }
      .plans-container .cards-container .info-container .plans-header .image2 {
        width: 50px;
        height: 80px;
        background: url("~app/resources/assets/plans/infomedia/infomedia_o2.png") no-repeat center;
        float: left;
        background-size: 55px; }
      .plans-container .cards-container .info-container .plans-header .image3 {
        width: 50px;
        height: 80px;
        background: url("~app/resources/assets/plans/infomedia/infomedia_three.png") no-repeat center;
        float: left;
        background-size: 55px; }
      .plans-container .cards-container .info-container .plans-header .image4 {
        width: 90px;
        height: 80px;
        background: url("~app/resources/assets/plans/infomedia/infomedia_vodafone.png") no-repeat center;
        float: left;
        background-size: 90px; }
      .plans-container .cards-container .info-container .plans-header.info-ee .image1 {
        width: 50px;
        height: 80px;
        background: url("~app/resources/assets/plans/infomedia/infomedia_ee.png") no-repeat center;
        float: left;
        background-size: 55px; }
      .plans-container .cards-container .info-container .plans-header.info-o2 .image1 {
        width: 50px;
        height: 80px;
        background: url("~app/resources/assets/plans/infomedia/infomedia_o2.png") no-repeat center;
        float: left;
        background-size: 55px; }
      .plans-container .cards-container .info-container .plans-header.info-three .image1 {
        width: 50px;
        height: 80px;
        background: url("~app/resources/assets/plans/infomedia/infomedia_three.png") no-repeat center;
        float: left;
        background-size: 55px; }
      .plans-container .cards-container .info-container .plans-header .text {
        float: left;
        font-size: 14px;
        margin: 0 3px; }
    .plans-container .cards-container .tpay-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .tpay-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .tpay-container .plans-header .image1 {
        height: 90px;
        float: left; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-avea .image1 {
        width: 103px;
        background: url("~app/resources/assets/plans/tpay/avea.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Du .image1 {
        width: 55px;
        background: url("~app/resources/assets/plans/tpay/du.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Tpay_Etisalat .image1 {
        width: 110px;
        background: url("~app/resources/assets/plans/tpay/etisalat.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-inwi .image1 {
        width: 129px;
        background: url("~app/resources/assets/plans/tpay/inwi.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Jawwal .image1 {
        width: 94px;
        background: url("~app/resources/assets/plans/tpay/jawwal.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Libyan .image1 {
        width: 170px;
        background: url("~app/resources/assets/plans/tpay/libyan.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-mobily .image1 {
        width: 112px;
        background: url("~app/resources/assets/plans/tpay/mobily.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Ooredoo .image1 {
        width: 116px;
        background: url("~app/resources/assets/plans/tpay/ooredoo.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Orange .image1 {
        width: 51px;
        background: url("~app/resources/assets/plans/tpay/orange.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-STC .image1 {
        width: 55px;
        height: 80px;
        background: url("~app/resources/assets/plans/tpay/stc.png") no-repeat center;
        float: left;
        background-size: 55px; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-tunisie-telecom .image1 {
        width: 64px;
        background: url("~app/resources/assets/plans/tpay/tunisie_telecom.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-turkcell .image1 {
        width: 134px;
        background: url("~app/resources/assets/plans/tpay/turkcell.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Umniah .image1 {
        width: 106px;
        background: url("~app/resources/assets/plans/tpay/umniah.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-viva .image1 {
        width: 123px;
        background: url("~app/resources/assets/plans/tpay/viva.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Tpay_Vodafone .image1 {
        width: 121px;
        background: url("~app/resources/assets/plans/tpay/vodafone.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-Wataniah .image1 {
        width: 147px;
        background: url("~app/resources/assets/plans/tpay/wataniah.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-WE .image1 {
        width: 147px;
        float: left;
        margin-left: -48px;
        margin-right: -48px;
        background: url("~app/resources/assets/plans/tpay/we.svg") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-wataneya .image1 {
        width: 147px;
        background: url("~app/resources/assets/plans/tpay/wataniah.png") no-repeat center; }
      .plans-container .cards-container .tpay-container .plans-header.tpay-zain .image1 {
        width: 100px;
        background: url("~app/resources/assets/plans/tpay/zain.png") no-repeat center; }
    .plans-container .cards-container .plans-header .arrow {
      width: 20px;
      height: 20px;
      background: url("~app/resources/assets/thumbnail/ic-right-arrow.png") no-repeat center;
      float: right;
      margin-top: 34px; }
    .plans-container .cards-container .plans {
      width: 100%;
      line-height: 60px;
      border-radius: 0 0 5px 5px;
      background-color: #1f1f1f;
      color: #ffffff;
      font-family: "changa";
      font-size: 18px;
      cursor: pointer;
      clear: both; }
      @media only screen and (max-width: 745px) {
        .plans-container .cards-container .plans {
          font-size: 14px; } }
      .plans-container .cards-container .plans .yearly {
        height: 40px; }
      .plans-container .cards-container .plans .plan-row {
        display: block;
        height: 60px;
        padding: 0;
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 40px;
        padding-right: 40px;
        background-color: #313131;
        border-top: 0; }
        @media only screen and (max-width: 745px) {
          .plans-container .cards-container .plans .plan-row {
            padding: 0 31px; } }
        .plans-container .cards-container .plans .plan-row .radio-div {
          display: inline-block;
          float: left;
          margin-top: 23px;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          border: 1px solid #919191; }
        .plans-container .cards-container .plans .plan-row .radio-div-selected {
          display: inline-block;
          float: left;
          margin-top: 23px;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          border: 1px solid #FF6D02;
          background: #FF6D02; }
        .plans-container .cards-container .plans .plan-row .plan-name {
          display: inline-block;
          margin-left: 10px;
          float: left; }
          @media only screen and (max-width: 745px) {
            .plans-container .cards-container .plans .plan-row .plan-name {
              margin-left: 10px;
              font-size: 11px; } }
        .plans-container .cards-container .plans .plan-row .trial_period {
          float: right;
          color: #6c6c6c; }
          @media only screen and (max-width: 745px) {
            .plans-container .cards-container .plans .plan-row .trial_period {
              font-size: 9px; } }
        .plans-container .cards-container .plans .plan-row .plans-separator {
          width: 100%;
          height: 1px;
          border: 1px solid #414141; }
        .plans-container .cards-container .plans .plan-row.selected .radio-div {
          border: 1px solid #ff740f;
          background: #ff740f; }
      .plans-container .cards-container .plans .Coupon-OfferTag {
        border: 1px solid white; }
      .plans-container .cards-container .plans .plan-row-yearly {
        display: block;
        padding: 9px 15px;
        border: 1px solid #FF6D02;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        margin-left: -10px;
        margin-right: -10px;
        background: #FF6D02;
        display: flex;
        justify-content: space-between;
        position: relative; }
        @media only screen and (max-width: 745px) {
          .plans-container .cards-container .plans .plan-row-yearly {
            padding: 0 5px; } }
        .plans-container .cards-container .plans .plan-row-yearly .radio-div {
          width: 12px;
          height: 12px;
          border-radius: 6px;
          border: 1px solid #919191;
          position: absolute;
          left: 24px;
          top: 30px; }
        .plans-container .cards-container .plans .plan-row-yearly .radio-div-selected {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 6px;
          border: 1px solid #007AFF;
          background: #007AFF;
          position: absolute;
          top: 32px;
          left: 25px;
          line-height: 14px;
          font-size: 10px;
          text-align: center; }
        .plans-container .cards-container .plans .plan-row-yearly .plan-name {
          display: inline-block;
          margin-left: 40px;
          float: left; }
          @media only screen and (max-width: 745px) {
            .plans-container .cards-container .plans .plan-row-yearly .plan-name {
              margin-left: 5px; } }
        .plans-container .cards-container .plans .plan-row-yearly .trial_period {
          float: right;
          color: #6c6c6c; }
          @media only screen and (max-width: 745px) {
            .plans-container .cards-container .plans .plan-row-yearly .trial_period {
              font-size: 9px; } }
        .plans-container .cards-container .plans .plan-row-yearly .plans-separator {
          width: 100%;
          height: 1px;
          border: 1px solid #414141; }
        .plans-container .cards-container .plans .plan-row-yearly.selected .radio-div {
          border: 1px solid #ff740f;
          background: #ff740f; }
    .plans-container .cards-container .coupon-container .plans-header {
      height: 90px;
      border-bottom: 1px solid #414141; }
      .plans-container .cards-container .coupon-container .plans-header div {
        line-height: 90px;
        display: inline-block; }
      .plans-container .cards-container .coupon-container .plans-header .image1 {
        width: 67px;
        height: 90px;
        background: url("~app/resources/assets/header/logo-01.svg") no-repeat center;
        float: left; }
      .plans-container .cards-container .coupon-container .plans-header .text {
        float: left;
        font-size: 14px;
        margin: 0 3px; }
    .plans-container .cards-container .coupon-input-outer-container {
      border-radius: 5px;
      background-color: #1f1f1f;
      padding-bottom: 5px; }
      .plans-container .cards-container .coupon-input-outer-container .coupon-input-container {
        display: flex;
        padding: 13px; }
        .plans-container .cards-container .coupon-input-outer-container .coupon-input-container .have-promo {
          line-height: 36px;
          min-width: 75px;
          font-size: 14px; }
          @media only screen and (max-width: 745px) {
            .plans-container .cards-container .coupon-input-outer-container .coupon-input-container .have-promo {
              width: 75px;
              font-size: 12px;
              line-height: 18px;
              text-align: left; } }
        .plans-container .cards-container .coupon-input-outer-container .coupon-input-container .coupon-input {
          flex: 1;
          min-width: 10px;
          height: 36px;
          border-radius: 8px;
          border: 1px solid #4b4b4b;
          font-size: 14px; }
        .plans-container .cards-container .coupon-input-outer-container .coupon-input-container .apply-button {
          min-width: 53px;
          width: 65px;
          height: 30px;
          margin-top: 4px;
          border-radius: 5px;
          background-color: #434343;
          font-size: 14px;
          text-transform: capitalize;
          padding: 0;
          cursor: auto; }
          .plans-container .cards-container .coupon-input-outer-container .coupon-input-container .apply-button span {
            line-height: 23px; }
          .plans-container .cards-container .coupon-input-outer-container .coupon-input-container .apply-button.enabled {
            cursor: pointer;
            background: #ff740f; }
      .plans-container .cards-container .coupon-input-outer-container .coupon-error {
        margin-top: 5px;
        font-size: 14px;
        color: red;
        text-align: left;
        width: 100%; }

.plans-container .plans_content .cards-container {
  width: 700px;
  margin: 30px 0 11px 0  !important; }

@media only screen and (max-width: 600px) {
  .plans-container .plans_content {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%; }
  .plans-container .plans_content .svod_ul li .left_svod .marT15 {
    font-size: 13px; }
  .plans-container .plans_content .svod_ul li .right_svod span:first-child {
    display: block;
    font-size: 11px; }
  .plans-container .plans_content .for_english {
    font-size: 14px !important; }
  .plans-container .plans_content .svod_ul li .right_svod span {
    font-size: 25px; } }

.plan-ribbon {
  display: flex;
  justify-content: space-between;
  color: #B4B4B4;
  padding: 0 14px;
  line-height: 40px;
  height: 40px;
  position: relative;
  top: 10px;
  background-repeat: no-repeat;
  background-color: #ffffff;
  border-radius: 8px; }
  @media only screen and (max-width: 745px) {
    .plan-ribbon {
      background-size: 146px 41px; } }
  .plan-ribbon s {
    color: #B4B4B4;
    margin-left: 5px;
    margin-right: 5px; }

.confirm-msg {
  color: #ff740f;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 10px;
  padding-left: 11px;
  padding-right: 10px;
  text-align: left; }

.rtl .radio-div-selected {
  left: inherit;
  right: 24px; }

.rtl .plan-row-yearly {
  padding: 9px 38px 9px 15px !important; }

.rtl .confirm-msg {
  text-align: right; }

.price {
  font-weight: bold  !important;
  color: #000000;
  padding-left: 3px;
  padding-right: 3px; }

@media only screen and (max-width: 1023px) {
  .plan-row-yearly {
    padding: 0 20px 0 35px !important; }
  .radio-div-selected {
    top: 24px !important;
    left: 15px !important; }
  .rtl .radio-div-selected {
    right: 14px !important; }
  .rtl .plan-row-yearly {
    padding: 0 30px 0 5px !important; } }

.rtl .plans-container .cards-container .adyen-container .plans-header .image2 {
  width: 50px;
  max-width: 69px;
  height: 90px;
  margin-left: 4px;
  float: none; }

.rtl .plans-container .cards-container .adyen-container .plans-header .image1 {
  width: 135px !important;
  height: 90px;
  margin-right: 4px; }

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .rtl .plans-container .cards-container .adyen-container .plans-header .image2 {
    width: 43px;
    max-width: 43px;
    height: 75px;
    float: right;
    background-position: 3px 34px;
    margin-left: 4px; }
  .rtl .plans-container .cards-container .adyen-container .plans-header .image1 {
    width: 78px !important;
    max-width: 78px;
    height: 90px;
    float: right;
    margin-left: 4px; }
  .rtl .plans-container .cards-container .adyen-container .plans-header div {
    line-height: 90px;
    display: inline-block; } }

.plans-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%; }

.ooredoo-ramadan {
  font: normal normal bold 12px/22px Changa;
  letter-spacing: 0px;
  color: #EC1C24;
  opacity: 1;
  position: absolute;
  bottom: 26px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-left: -62px;
  background-color: #313131;
  padding-bottom: 12px;
  padding-left: 38px;
  margin-bottom: -14px; }

.ooredoo-plan {
  height: 80px; }

.ooredoo-gift {
  display: flex; }

.ooredoo-ramadan-ar {
  font: normal normal bold 12px/22px Changa;
  letter-spacing: 0px;
  color: #EC1C24;
  opacity: 1;
  position: absolute;
  bottom: 25px !important;
  display: flex;
  align-items: flex-start;
  margin-right: -62px;
  padding-right: 35px;
  background-color: #313131;
  width: 100%;
  margin-bottom: -15px;
  padding-bottom: 12px;
  padding-left: 10px; }

.plans-container .plans_content .svod_ul li .arrow_down1 .arrow_down {
  margin-bottom: -15px; }

@media only screen and (max-width: 745px) {
  .ooredoo-ramadan {
    margin-left: -53px;
    padding-left: 29px; }
  .ooredoo-ramadan-ar {
    margin-right: -48px;
    padding-right: 28px;
    bottom: 8px;
    padding-bottom: 0px !important; } }
