/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .image-carousel .pagination-dots.swiper-pagination-bullets {
  bottom: 20px; }

.subscribe-thumbnail {
  cursor: pointer; }

.featured-cover-container .image-carousel .swiper-container {
  width: 100vw; }

.image-carousel .swiper-container {
  overflow: hidden;
  margin: 0;
  position: relative; }

.image-carousel .pagination-dots.swiper-pagination-bullets {
  position: absolute;
  bottom: 15px;
  z-index: 1;
  margin: 0 auto;
  height: 20px;
  width: 100%;
  text-align: center; }
  .image-carousel .pagination-dots.swiper-pagination-bullets span {
    border-radius: 0;
    opacity: 0.5;
    font-size: 0;
    line-height: 0;
    display: inline-block;
    width: 30px;
    padding: 0;
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;
    border-bottom: 2px solid #fff; }
    .image-carousel .pagination-dots.swiper-pagination-bullets span.swiper-pagination-bullet-active {
      border-bottom: 2px solid #f9710b;
      opacity: 1; }

.Horizontal-menu {
  width: 100vw; }
  .Horizontal-menu .slick-dots {
    bottom: 25px; }
    .Horizontal-menu .slick-dots .button::before {
      opacity: 1;
      color: #f9710b; }
    .Horizontal-menu .slick-dots li {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      margin: 0 4px;
      padding: 0;
      cursor: pointer; }
      .Horizontal-menu .slick-dots li.slick-active button {
        border-bottom: 2px solid #f9710b;
        opacity: 1; }
      .Horizontal-menu .slick-dots li button {
        opacity: 0.5;
        font-size: 0;
        line-height: 0;
        display: inline-block;
        width: 30px;
        height: 21px;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background: transparent;
        border-bottom: 2px solid #fff; }
        .Horizontal-menu .slick-dots li button::before {
          display: none; }
