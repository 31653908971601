/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.email-verification-success-container {
  color: #fff;
  min-height: calc(100vh - 80px - 185px); }
  .email-verification-success-container .email-verification-success-content {
    width: 100%;
    text-align: center;
    padding-bottom: 4%;
    padding-top: 5%; }
    .email-verification-success-container .email-verification-success-content p {
      text-align: center;
      padding-bottom: 18px; }
    .email-verification-success-container .email-verification-success-content .email-row {
      padding-bottom: 40px;
      margin-top: 77px; }
      .email-verification-success-container .email-verification-success-content .email-row strong {
        font-weight: 700; }
  @media (max-width: 745px) {
    .email-verification-success-container {
      min-height: calc(100vh - 60px - 175px); }
      .email-verification-success-container .email-verification-success-content {
        width: 80%;
        margin: auto; } }
  .email-verification-success-container .btn-container {
    display: flex;
    justify-content: center;
    padding-bottom: 16%; }
    .email-verification-success-container .btn-container .btn-done {
      width: 280px;
      height: 46px;
      border-radius: 5px;
      background-color: #ff740f; }
    @media (max-width: 745px) {
      .email-verification-success-container .btn-container {
        flex-direction: column; }
        .email-verification-success-container .btn-container .btn {
          width: auto;
          margin: 10px; } }
