/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.mobile-verification-success-container {
  background: black;
  color: #fff;
  min-height: calc(100vh - 80px - 185px); }
  .mobile-verification-success-container .desktop-icon {
    display: flex;
    margin: 35px auto 10px; }
  .mobile-verification-success-container .mobile-verification-content {
    width: 100%;
    text-align: center;
    padding-bottom: 4%;
    padding-top: 5%; }
    .mobile-verification-success-container .mobile-verification-content p {
      text-align: center;
      padding-bottom: 18px; }
    @media (max-width: 745px) {
      .mobile-verification-success-container .mobile-verification-content {
        width: 80%;
        margin: auto; } }
  .mobile-verification-success-container .btn-container {
    display: flex;
    padding-bottom: 16%;
    flex-direction: column;
    align-items: center;
    margin-top: 50px; }
    .mobile-verification-success-container .btn-container .btn-done {
      width: 280px;
      height: 46px;
      border-radius: 5px;
      background-color: #ff740f; }
    @media (max-width: 745px) {
      .mobile-verification-success-container .btn-container {
        flex-direction: column; }
        .mobile-verification-success-container .btn-container .btn {
          width: auto;
          margin: 10px; } }
