/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .settings-container .settings .arrow {
  right: auto;
  left: 0;
  transform: rotate(180deg);
  font-family: "changa"; }

.settings-container {
  background-color: #0F1822;
  color: #4a4a4a;
  display: flex;
  font-family: "changa";
  justify-content: center;
  min-height: calc(100vh - 185px - 60px); }
  .settings-container .settings {
    width: calc(100% - 25vw);
    display: flex;
    flex-direction: column;
    margin: 0px 0 60px 0; }
    .settings-container .settings .title {
      margin-top: 100px;
      margin-bottom: 20px;
      color: #ffffff;
      font-size: 24px;
      text-align: center; }
    .settings-container .settings .setting-item {
      padding: 10px 0;
      border-bottom: 1px solid #303030;
      position: relative;
      cursor: pointer; }
    .settings-container .settings .setting-item-title {
      font-size: 16px;
      font-weight: bold; }
    .settings-container .settings .setting-item-desc {
      font-size: 16px; }
    .settings-container .settings .arrow {
      background: url("~app/resources/assets/arrow-seeting.svg");
      width: 11px;
      height: 18px;
      position: absolute;
      top: 50%;
      right: 0; }
  @media only screen and (max-width: 745px) {
    .settings-container {
      min-height: calc(100vh - 175px - 60px); } }
