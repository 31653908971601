/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .reset-password-mobile .close-btn {
  right: auto;
  left: 20px; }

.app-body[dir="rtl"] .reset-password-mobile .forgot-password-container .email-input img {
  right: auto;
  left: 15px; }

.app-body[dir="rtl"] .reset-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button {
  font-size: 16px; }
  .app-body[dir="rtl"] .reset-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button.highlight {
    margin-right: 0;
    margin-left: 10px; }

.app-body[dir="rtl"] .reset-password-mobile .forgot-password-container .sms-code-input img, .app-body[dir="rtl"] .reset-password-mobile .forgot-password-container .new-password-input img {
  right: auto;
  left: 15px; }

.resend-code {
  width: 100%;
  height: 18px;
  margin: 10px 0;
  padding: 0 10px;
  font-size: 14px;
  text-align: center; }
  .resend-code .resend-text {
    color: gray;
    text-decoration: underline; }
    .resend-code .resend-text.active {
      color: white;
      cursor: pointer; }
  .resend-code .timer {
    color: #5d5d5d;
    margin: 0 5px; }
  @media (max-width: 745px) {
    .resend-code {
      font-size: 12px; } }

.reset-password-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding: 110px 0;
  color: #fff;
  position: relative;
  font-family: "changa";
  min-height: calc( 100vh - 185px - 80px); }
  .reset-password-mobile .close-btn {
    display: flex;
    position: absolute;
    right: 22px;
    top: 14px;
    background: transparent; }
  .reset-password-mobile .forgot-password-container {
    width: 30%;
    min-width: 376px; }
    .reset-password-mobile .forgot-password-container .resend-sms-code {
      font-size: 18px;
      height: 65px;
      line-height: 65px;
      width: 100%;
      white-space: nowrap;
      color: #4e4e4e;
      text-decoration: underline;
      cursor: pointer;
      text-align: center; }
    .reset-password-mobile .forgot-password-container .forgot-password-title {
      font-size: 24px;
      height: 65px;
      line-height: 65px;
      width: 100%;
      white-space: nowrap;
      color: #4e4e4e; }
      .reset-password-mobile .forgot-password-container .forgot-password-title p {
        margin: 0;
        text-align: center;
        color: #fff; }
    .reset-password-mobile .forgot-password-container .description {
      width: 100%;
      height: auto;
      line-height: 28px;
      margin-top: 36px;
      font-size: 20px;
      margin-bottom: 26px;
      color: #4e4e4e; }
      .reset-password-mobile .forgot-password-container .description p {
        margin: 0; }
    .reset-password-mobile .forgot-password-container .description.informative {
      font-size: 20px;
      max-width: 880px;
      text-align: center; }
      .reset-password-mobile .forgot-password-container .description.informative p {
        padding-bottom: 25px; }
    .reset-password-mobile .forgot-password-container .sms-code-input, .reset-password-mobile .forgot-password-container .new-password-input {
      position: relative;
      margin: 10px 0; }
      .reset-password-mobile .forgot-password-container .sms-code-input img, .reset-password-mobile .forgot-password-container .new-password-input img {
        position: absolute;
        right: 15px;
        top: 14px; }
      .reset-password-mobile .forgot-password-container .sms-code-input input, .reset-password-mobile .forgot-password-container .new-password-input input {
        margin: 0;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 37px 0 20px;
        color: #646464;
        border-radius: 5px;
        caret-color: #4e4e4e;
        border: 2px solid #414141; }
    .reset-password-mobile .forgot-password-container .label {
      height: 19px;
      color: #5d5d5d;
      font-size: 18px;
      margin-bottom: 10px; }
    .reset-password-mobile .forgot-password-container .forgot-password-buttons {
      margin-top: 36px;
      display: flex;
      flex-direction: row; }
      .reset-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button {
        text-transform: none;
        width: 100%;
        height: 46px;
        background-color: #ff740f;
        margin: 0 10px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        color: rgba(0, 0, 0, 0.38); }
        .reset-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button.return-button, .reset-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button.enable {
          cursor: pointer;
          color: #fff; }
      @media screen and (max-width: 745px) {
        .reset-password-mobile .forgot-password-container .forgot-password-buttons {
          flex-direction: column; } }
    .reset-password-mobile .forgot-password-container .forgot-password-buttons.informative {
      margin-top: 93px; }
    @media (max-width: 745px) {
      .reset-password-mobile .forgot-password-container .forgot-password-title {
        font-size: 20px;
        margin-top: 20px; }
      .reset-password-mobile .forgot-password-container .description {
        font-size: 18px; }
      .reset-password-mobile .forgot-password-container .email-input {
        font-size: 18px; }
      .reset-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button {
        font-size: 16px; }
      .reset-password-mobile .forgot-password-container .forgot-password-buttons.informative {
        margin-top: 50px; } }
    @media screen and (max-width: 745px) {
      .reset-password-mobile .forgot-password-container {
        width: calc(100% - 20vw);
        min-width: auto; } }
  @media screen and (max-width: 745px) {
    .reset-password-mobile {
      padding: 0;
      min-height: calc( 100vh - 185px - 80px); } }

.forgot-password.arabic .forgot-password-container .description {
  text-align: right; }

.forgot-password.arabic .forgot-password-container .description.informative {
  text-align: center; }

.forgot-password.arabic .forgot-password-container .email-input {
  background-position: calc(0% + 11px) 13px;
  text-align: right;
  padding: 0 20px 0 37px; }
