/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.reset-password-success-container {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 185px - 80px); }
  .reset-password-success-container .password-success {
    width: calc(100% - 30vw);
    text-align: center; }
    .reset-password-success-container .password-success h2 {
      color: #ff740f; }
  .reset-password-success-container .rectangle-box {
    height: 1px;
    background-color: #d7d7d7;
    opacity: 0.2; }
  .reset-password-success-container .description {
    color: #838383;
    font-size: 18px;
    margin-top: 143px;
    margin-bottom: 118px; }
  .reset-password-success-container .btn-return-to-login .return-button {
    height: 50px;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #ff740f;
    padding: 0 50px; }
    @media screen and (max-width: 745px) {
      .reset-password-success-container .btn-return-to-login .return-button {
        width: 100%;
        padding: 0; } }
  @media screen and (max-width: 745px) {
    .reset-password-success-container {
      min-height: calc(100vh - 185px - 80px); }
      .reset-password-success-container .description {
        color: #838383;
        font-size: 12px; } }
