/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .about-overlay .close-btn {
  right: auto;
  left: 20px; }

.about-overlay {
  width: 100%;
  min-height: calc(100vh - 50px - 186px);
  background-color: #0F1822; }
  .about-overlay .close-btn {
    position: absolute;
    top: 27px;
    right: 28px;
    width: 30px;
    height: 30px; }
    @media (max-width: 745px) {
      .about-overlay .close-btnhover {
        background-color: #ff740f; } }
    .about-overlay .close-btn .icon {
      top: 0; }
  .about-overlay .about-overlay-container {
    width: 100%;
    padding: 0 70px;
    max-width: 1300px;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .about-overlay .about-overlay-container {
        padding: 73px 30px; } }
    .about-overlay .about-overlay-container .static-page {
      padding: 45px 70px; }
      @media (max-width: 745px) {
        .about-overlay .about-overlay-container .static-page {
          padding: 0 10px; } }
      .about-overlay .about-overlay-container .static-page .overlay-title {
        color: #ff740f;
        max-width: 1300px;
        font-size: 26px;
        width: 100%;
        margin: 0 auto !important;
        background-color: #0F1822;
        padding: 72px 0 0;
        font-weight: bold;
        text-align: center; }
      .about-overlay .about-overlay-container .static-page .static-content {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        background-color: #0F1822;
        color: #6c6c6c;
        padding: 60px;
        text-align: justify; }
        @media (max-width: 745px) {
          .about-overlay .about-overlay-container .static-page .static-content {
            padding: 15px; } }
        .about-overlay .about-overlay-container .static-page .static-content p:nth-child(odd) {
          font-weight: bold; }
      .about-overlay .about-overlay-container .static-page.arabic {
        direction: rtl; }
  .about-overlay .overlays-close {
    position: fixed;
    top: 65px;
    right: 15px;
    display: inline-block;
    width: 25px;
    height: 25px;
    overflow: hidden;
    cursor: pointer; }
    .about-overlay .overlays-close::before, .about-overlay .overlays-close::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: white; }
    .about-overlay .overlays-close::before {
      transform: rotate(45deg); }
    .about-overlay .overlays-close::after {
      transform: rotate(-45deg); }
  .about-overlay .about-heading {
    text-align: center;
    padding-top: 57px; }
    .about-overlay .about-heading h1 {
      color: white;
      font-size: 81px;
      font-family: Changa;
      font-weight: bold;
      margin-bottom: 18px;
      margin-top: 0; }
      @media (max-width: 767px) {
        .about-overlay .about-heading h1 {
          font: normal normal bold 31px/71px Changa;
          letter-spacing: 0px; } }
  .about-overlay .image-top {
    text-align: center; }
    @media (max-width: 767px) {
      .about-overlay .image-top img {
        width: 100%; } }
  .about-overlay .description {
    color: white;
    text-align: center;
    font-family: Changa,Regular;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 31px; }
    @media (max-width: 767px) {
      .about-overlay .description {
        font: normal normal normal 16px/23px Changa;
        letter-spacing: 0px;
        color: #FFFFFF; } }
  .about-overlay .middle-heading {
    color: #707070;
    font-size: 26px;
    font-family: Changa,Regular;
    letter-spacing: 13px;
    text-align: center;
    margin-top: 38px; }
    @media (max-width: 767px) {
      .about-overlay .middle-heading {
        font: normal normal normal 16px/30px Changa; } }
  .about-overlay .middle-heading2 {
    color: white;
    font-size: 81px;
    font-family: Changa;
    font-weight: bold;
    text-align: center;
    margin-top: -45px;
    margin-bottom: 15px; }
    @media (max-width: 767px) {
      .about-overlay .middle-heading2 {
        margin-top: -23px;
        text-align: center;
        font: normal normal bold 31px/71px Changa;
        letter-spacing: 0px;
        color: #FFFFFF; } }
  .about-overlay .image-bottom {
    text-align: center;
    margin-bottom: 10px;
    margin-top: -96px !important; }
    @media (max-width: 745px) {
      .about-overlay .image-bottom {
        margin-top: -1px !important; } }
    @media (max-width: 745px) {
      .about-overlay .image-bottom img {
        width: 100%; } }
  .about-overlay .bottom-text {
    color: #707070;
    font-size: 26px;
    font-family: Changa,Regular;
    text-align: center;
    margin-top: -57px; }
    @media (max-width: 767px) {
      .about-overlay .bottom-text {
        text-align: center;
        font: normal normal normal 16px/16px Changa;
        letter-spacing: 0px;
        color: #707070;
        margin: 0; } }

.overlays.ng-enter {
  transition: 0.3s ease-in-out all;
  opacity: 0; }

.overlays.arabic .overlays-close {
  position: fixed;
  right: auto;
  left: 15px; }

.overlays.ng-enter.ng-enter-active {
  opacity: 1; }
