/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.checkbox-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin: 16px 0; }
  .checkbox-container .checkbox-text {
    margin: 0 10px;
    color: #5d5d5d;
    cursor: pointer; }
  .checkbox-container .checkbox {
    position: relative;
    margin: 10px 0;
    width: 18px;
    height: 18px;
    border: 2px solid #5a5a5a;
    cursor: pointer; }
    .checkbox-container .checkbox input[type="checkbox"] {
      visibility: hidden;
      width: 325px;
      padding: 0 20px;
      font-size: 20px;
      color: #646464;
      border: none;
      background-color: #fff; }
    .checkbox-container .checkbox.selected {
      border: none;
      background: #ff740f; }
      .checkbox-container .checkbox.selected .checkbox-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        background: url("~app/resources/assets/tick.svg") center no-repeat; }
