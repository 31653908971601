/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 @import "app/resources/styles/variables.scss";
.menuItem {
  border-radius: 30px;
 
}
.app-body[dir="rtl"] {
  .menuItem {
    .user-icon{
      margin-left: 20px;
      margin-right: unset;
    }
  }
}
.menuItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  .user-icon{
    height: 16px;
    padding-left: 0px;
    color: #757575;
    font: normal normal 300 16px / 30px Changa;
    position: relative;
    margin-right: 10px;
  }
  .icon-menu-container{

  }
  span{
   background-color: transparent !important;
 }
}