/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.email-verification-container {
  color: #fff;
  min-height: calc(100vh - 80px - 185px); }
  .email-verification-container .email-verification-content {
    width: 100%;
    text-align: center;
    font-size: 20px; }
    .email-verification-container .email-verification-content .margin-collpse {
      height: 1px; }
    .email-verification-container .email-verification-content .email-row {
      margin-top: 147px; }
      .email-verification-container .email-verification-content .email-row strong {
        font-weight: 700; }
    .email-verification-container .email-verification-content .note {
      color: #9c9c9c;
      margin-top: 40px;
      font-size: 18px;
      line-height: 23px; }
      @media (max-width: 745px) {
        .email-verification-container .email-verification-content .note {
          font-size: 16px; } }
    @media (max-width: 745px) {
      .email-verification-container .email-verification-content {
        width: 80%;
        margin: 0 10%;
        font-size: 16px; } }
  .email-verification-container .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px; }
    .email-verification-container .btn-container .btn-resend, .email-verification-container .btn-container .btn-login {
      margin: 10px 0;
      width: 258px;
      height: 42px;
      border-radius: 5px;
      background-color: #ff740f; }
    .email-verification-container .btn-container .btn-login {
      margin-bottom: 100px; }
    @media (max-width: 745px) {
      .email-verification-container .btn-container {
        flex-direction: column; }
        .email-verification-container .btn-container .btn {
          width: 80%;
          font-size: 16px; } }
  @media (max-width: 745px) {
    .email-verification-container {
      min-height: calc(100vh - 80px - 185px); } }
