/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .plans-description-container .time-indicator .seven-days-free {
  left: unset;
  right: 10%; }

.app-body[dir="rtl"] .plans-description-container .time-indicator .thirty-seven-days-free {
  right: unset;
  left: 10%; }

.app-body[dir="rtl"] .plans-description-container .time-indicator .circle-indicator1 {
  left: unset;
  right: 10%; }

.app-body[dir="rtl"] .plans-description-container .time-indicator .line1 {
  left: unset;
  right: 10%; }

.app-body[dir="rtl"] .plans-description-container .time-indicator .circle-indicator2 {
  left: unset;
  right: 23%; }

.app-body[dir="rtl"] .plans-description-container .time-indicator .line2 {
  left: unset;
  right: 23%; }

.app-body[dir="rtl"] .plans-description-container .time-indicator .circle-indicator3 {
  right: unset;
  left: 10%; }

.plans-description-container {
  width: 100%;
  min-height: calc(100vh - 245px);
  background-color: #0F1822;
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  font-family: "changa";
  margin-top: 100px; }
  @media only screen and (max-width: 745px) {
    .plans-description-container {
      min-height: calc(100vh - 235px); } }
  .plans-description-container .try-for-free {
    margin-top: 50px;
    margin-bottom: 10px;
    width: 669px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 800; }
    @media only screen and (max-width: 745px) {
      .plans-description-container .try-for-free {
        width: 304px;
        font-size: 20px; } }
  .plans-description-container .try-for-free1 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 800; }
  .plans-description-container .try-for-free2 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 800px; }
  .plans-description-container .time-indicator {
    display: flex;
    height: 50px;
    font-size: 16px;
    margin-top: 10px; }
    @media only screen and (max-width: 745px) {
      .plans-description-container .time-indicator {
        font-size: 14px; } }
    .plans-description-container .time-indicator .seven-days-free {
      position: absolute;
      left: 10%; }
    .plans-description-container .time-indicator .thirty-seven-days-free {
      position: absolute;
      right: 10%; }
    .plans-description-container .time-indicator .circle {
      margin-top: 25px;
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 7px; }
    .plans-description-container .time-indicator .line {
      position: absolute;
      height: 2px;
      margin-top: 31px; }
    .plans-description-container .time-indicator .circle-indicator1 {
      left: 10%;
      background-color: #067f02; }
    .plans-description-container .time-indicator .line1 {
      left: 10%;
      width: 23%;
      background-color: #067f02; }
    .plans-description-container .time-indicator .circle-indicator2 {
      left: 23%;
      background-color: #ff6d02; }
    .plans-description-container .time-indicator .line2 {
      left: 23%;
      width: 67%;
      background-color: #ff6d02; }
    .plans-description-container .time-indicator .circle-indicator3 {
      right: 10%;
      background-color: #ff6d02; }
  .plans-description-container .subscription-desc1,
  .plans-description-container .subscription-desc2 {
    width: 420px;
    color: #ffffff;
    font-size: 16px; }
    @media only screen and (max-width: 745px) {
      .plans-description-container .subscription-desc1,
      .plans-description-container .subscription-desc2 {
        width: 290px;
        height: 68px;
        font-size: 14px;
        line-height: 17px; } }
  .plans-description-container .subscribe-now-button {
    margin: 50px 0;
    width: 229px;
    height: 46px;
    border-radius: 8px;
    background-color: #ff6d02;
    font-size: 18px; }
    @media only screen and (max-width: 745px) {
      .plans-description-container .subscribe-now-button {
        margin: 25px 0;
        width: 230px;
        height: 37px;
        border-radius: 5px;
        font-size: 14px; } }
  .plans-description-container .icons-list {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center; }
    .plans-description-container .icons-list .container {
      width: 25%; }
    .plans-description-container .icons-list .icon {
      height: 39px;
      margin: 0;
      background-position: center;
      background-repeat: no-repeat; }
    .plans-description-container .icons-list .text {
      margin-top: 5px;
      font-size: 16px; }
      @media only screen and (max-width: 745px) {
        .plans-description-container .icons-list .text {
          font-size: 14px;
          line-height: 16px; } }
    .plans-description-container .icons-list .no-ads .icon {
      background-image: url("~app/resources/assets/subscribe/no_ads.svg"); }
    .plans-description-container .icons-list .smart-tv .icon {
      background-image: url("~app/resources/assets/subscribe/smarttv.png"); }
    .plans-description-container .icons-list .exclusive-content .icon {
      background-image: url("~app/resources/assets/subscribe/exclusive_content.svg"); }
    .plans-description-container .icons-list .downloads .icon {
      background-image: url("~app/resources/assets/subscribe/downloads.svg"); }
    .plans-description-container .icons-list .hd-content .icon {
      background-image: url("~app/resources/assets/subscribe/hd_content.svg"); }
  .plans-description-container .subscrition-image-1 {
    margin-top: 50px;
    width: 1440px;
    height: 722px;
    background-size: contain;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 745px) {
      .plans-description-container .subscrition-image-1 {
        width: 548px;
        height: 322px; } }

.new-description-plan-data .plans-description-container .time-indicator {
  position: relative;
  max-width: 100%;
  width: 1000px; }

.new-description-plan-data .icons-list {
  margin-top: 0;
  max-width: 1080px;
  width: 100%;
  margin-bottom: 20px; }

.new-description-plan-data .subscribe-now-button {
  margin: 15px 0 40px 0; }

.new-description-plan-data .plans-description-container .try-for-free {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  width: 100%;
  font-family: Changa;
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .new-description-plan-data .plans-description-container .try-for-free {
      font-size: 20px;
      line-height: 24px; } }

.new-description-plan-data .subhead {
  font-weight: 200;
  font-size: 26px;
  font-style: normal;
  font-family: Changa; }
  @media (max-width: 767px) {
    .new-description-plan-data .subhead {
      font-size: 20px; } }

.new-description-plan-data .subscrition-image-3 {
  margin-top: 60px;
  width: 1090px;
  height: 492px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  border-radius: 6px; }
  .new-description-plan-data .subscrition-image-3 p {
    display: block;
    text-align: left;
    margin-top: -35px;
    padding-left: 15px; }
  @media only screen and (max-width: 745px) {
    .new-description-plan-data .subscrition-image-3 {
      width: 548px;
      height: 322px; } }
  .new-description-plan-data .subscrition-image-3 .plans-description-container .time-indicator {
    margin-top: 0; }

.new-description-plan-data .subscribe-thumb-grid {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding-bottom: 30px; }
  @media (max-width: 767px) {
    .new-description-plan-data .subscribe-thumb-grid {
      width: auto; } }
  .new-description-plan-data .subscribe-thumb-grid ul {
    padding: 0;
    margin: 0;
    display: flex; }
    @media (max-width: 767px) {
      .new-description-plan-data .subscribe-thumb-grid ul {
        display: block;
        text-align: center; } }
    .new-description-plan-data .subscribe-thumb-grid ul li {
      display: inline-block;
      list-style: none;
      cursor: pointer;
      margin-right: 14px; }
      @media (max-width: 767px) {
        .new-description-plan-data .subscribe-thumb-grid ul li {
          margin-bottom: 10px; }
          .new-description-plan-data .subscribe-thumb-grid ul li:nth-child(even) {
            margin-right: 0; } }
      .new-description-plan-data .subscribe-thumb-grid ul li img {
        border-radius: 4px;
        width: 168px; }
      .new-description-plan-data .subscribe-thumb-grid ul li:last-child {
        margin-right: 0; }

.subscribeslider {
  width: 100%;
  max-width: 1080px;
  height: 488px;
  margin-bottom: 15px;
  border-radius: 6px;
  overflow: hidden; }
  .subscribeslider .image-thumbnail .carousel-image :last-child {
    opacity: 0; }
  @media (max-width: 767px) {
    .subscribeslider {
      padding-left: 20px;
      padding-right: 20px;
      height: auto; } }
  .subscribeslider .banner-label P {
    font-size: 24px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: left; }
  .subscribeslider .swiper-container img {
    width: 100%;
    object-fit: contain;
    height: 488px; }
    @media (max-width: 767px) {
      .subscribeslider .swiper-container img {
        height: auto; } }
  .subscribeslider .image-carousel .pagination-dots.swiper-pagination-bullets {
    bottom: 30px; }
    .subscribeslider .image-carousel .pagination-dots.swiper-pagination-bullets span {
      height: 5px;
      background-color: rgba(255, 255, 255, 0.5);
      border: 0;
      border-radius: 20px;
      width: 22px;
      margin-right: 5px;
      opacity: 1; }
      .subscribeslider .image-carousel .pagination-dots.swiper-pagination-bullets span.swiper-pagination-bullet-active {
        opacity: 1;
        background-color: white; }

.app-body.rtl .subscribeslider .banner-label P {
  text-align: right; }
