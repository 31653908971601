/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .change-pass-container .change-pass .change-pass-form .change-pass-field img {
  right: auto;
  left: 15px; }

.app-body[dir="rtl"] .change-pass-container .change-pass .change-pass-form .forgot-pass {
  text-align: left; }

.change-pass-container {
  background-color: #0F1822;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 185px - 50px);
  font-family: "changa"; }
  .change-pass-container .change-pass {
    width: calc(100% - 50vw);
    margin-bottom: 40px; }
    @media screen and (max-width: 745px) {
      .change-pass-container .change-pass {
        width: calc(100% - 20vw); } }
    .change-pass-container .change-pass .overlay-title {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      margin: 85px 0;
      text-align: center; }
    .change-pass-container .change-pass .insert-password {
      text-align: center;
      margin-bottom: 40px; }
    .change-pass-container .change-pass .confirm-password {
      text-align: center;
      margin: 30px 0; }
    .change-pass-container .change-pass .change-pass-form .forgot-pass {
      display: none;
      color: #4470ca;
      text-align: right; }
      .change-pass-container .change-pass .change-pass-form .forgot-pass span {
        cursor: pointer; }
    .change-pass-container .change-pass .change-pass-form .label {
      height: 21px;
      color: #4a4a4a;
      font-size: 18px;
      margin-bottom: 10px; }
    .change-pass-container .change-pass .change-pass-form .change-pass-field {
      position: relative;
      background-color: #0F1822;
      border-radius: 5px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      margin: 5px 0 20px;
      border-radius: 3px;
      border: 2px solid #4a4a4a; }
      @media screen and (max-width: 745px) {
        .change-pass-container .change-pass .change-pass-form .change-pass-field {
          padding: 0 10px; } }
      .change-pass-container .change-pass .change-pass-form .change-pass-field input {
        max-width: 376px;
        width: 100%;
        height: 44px;
        line-height: 44px; }
      .change-pass-container .change-pass .change-pass-form .change-pass-field img {
        position: absolute;
        right: 15px; }
      .change-pass-container .change-pass .change-pass-form .change-pass-field.new {
        margin-bottom: 0; }
    .change-pass-container .change-pass .change-pass-form .newpass-error {
      color: #bd0000;
      font-style: normal;
      padding: 4px;
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 400;
      height: 18px;
      font-size: 12px;
      position: relative;
      align-items: baseline; }
    .change-pass-container .change-pass .change-pass-form .change-pass-checkout {
      margin: 40px 0; }
    .change-pass-container .change-pass .change-pass-form .checkbox-text {
      color: #4e4e4e; }
    .change-pass-container .change-pass .change-pass-form .checkbox-inner {
      border: 1px solid #fff; }
    .change-pass-container .change-pass .change-pass-form .change-pass-buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px; }
      @media screen and (max-width: 745px) {
        .change-pass-container .change-pass .change-pass-form .change-pass-buttons {
          flex-direction: column;
          align-items: center; } }
      .change-pass-container .change-pass .change-pass-form .change-pass-buttons .btn-cancel,
      .change-pass-container .change-pass .change-pass-form .change-pass-buttons .btn-change-pass {
        border-radius: 5px;
        height: 45px;
        flex-basis: 50%;
        padding: 10px;
        width: 248px; }
        @media screen and (max-width: 745px) {
          .change-pass-container .change-pass .change-pass-form .change-pass-buttons .btn-cancel,
          .change-pass-container .change-pass .change-pass-form .change-pass-buttons .btn-change-pass {
            font-size: 16px;
            margin-bottom: 10px;
            width: 85%; } }
      .change-pass-container .change-pass .change-pass-form .change-pass-buttons .btn-change-pass {
        background: #ff740f;
        color: #fff; }
      .change-pass-container .change-pass .change-pass-form .change-pass-buttons .btn-cancel {
        background-color: #414141;
        color: #9b9b9b; }
  .change-pass-container .rodal-dialog {
    padding: 0; }
    @media screen and (max-width: 745px) {
      .change-pass-container .rodal-dialog {
        width: 85% !important;
        height: 190px !important; } }
  .change-pass-container .signout-dialog-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .change-pass-container .signout-dialog-content .content {
      padding: 24px;
      text-align: center; }
    .change-pass-container .signout-dialog-content .dialog-title {
      color: #ff740f;
      margin-left: auto;
      margin-right: auto;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.005em;
      margin-bottom: 10px; }
    .change-pass-container .signout-dialog-content .dialog-text {
      color: #606060; }
    .change-pass-container .signout-dialog-content .actions {
      display: flex;
      height: 36px; }
    .change-pass-container .signout-dialog-content .dialog-ok-btn {
      border-top: 1px solid #606060;
      border-bottom: 1px solid #606060;
      width: 100%;
      text-transform: none;
      color: #606060;
      padding: 0;
      margin: 0 0 -17px;
      border-radius: 0;
      background: #fff;
      font-size: 14px;
      height: 36px; }
      .change-pass-container .signout-dialog-content .dialog-ok-btn:hover {
        background: rgba(158, 158, 158, 0.2); }
