/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.signup-container {
  min-height: 76vh;
  display: flex;
  justify-content: center;
  font-family: "changa"; }
  .signup-container .sign-up {
    width: 85%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 1024px) {
      .signup-container .sign-up {
        width: 100%; } }
    .signup-container .sign-up .overlay-title {
      color: #ebebeb;
      font-size: 20px;
      margin: 100px 0 30px;
      text-transform: uppercase; }
      @media (max-width: 1024px) {
        .signup-container .sign-up .overlay-title {
          margin-top: 70px; } }
    .signup-container .sign-up .register-container {
      display: flex; }
      @media (max-width: 1024px) {
        .signup-container .sign-up .register-container {
          width: 80%;
          max-width: 400px;
          display: block; } }
      .signup-container .sign-up .register-container .input-container {
        margin-top: 20px; }
        .signup-container .sign-up .register-container .input-container .email-button,
        .signup-container .sign-up .register-container .input-container .mobile-button {
          margin: 0;
          border-radius: 5px;
          background-color: #414141;
          color: #fff;
          font-size: 19px;
          width: 400px;
          height: 53px; }
          .signup-container .sign-up .register-container .input-container .email-button.expand,
          .signup-container .sign-up .register-container .input-container .mobile-button.expand {
            background-color: #ff740f; }
          @media (max-width: 1024px) {
            .signup-container .sign-up .register-container .input-container .email-button,
            .signup-container .sign-up .register-container .input-container .mobile-button {
              min-width: 250px;
              width: 100%; } }
          @media (max-width: 745px) {
            .signup-container .sign-up .register-container .input-container .email-button,
            .signup-container .sign-up .register-container .input-container .mobile-button {
              font-size: 16px; } }
        .signup-container .sign-up .register-container .input-container .email-expand,
        .signup-container .sign-up .register-container .input-container .mobile-expand {
          width: 400px;
          background-color: #242424;
          padding: 10px 14px; }
          @media (max-width: 1024px) {
            .signup-container .sign-up .register-container .input-container .email-expand,
            .signup-container .sign-up .register-container .input-container .mobile-expand {
              width: 100%; } }
        .signup-container .sign-up .register-container .input-container .checkbox-container {
          margin-top: 0; }
    .signup-container .sign-up .or-container {
      width: 400px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 30px 0; }
      @media (max-width: 1024px) {
        .signup-container .sign-up .or-container {
          width: 80%;
          height: 1px;
          margin: 25px auto; } }
      .signup-container .sign-up .or-container.second {
        width: 120px;
        margin: 25px auto; }
        @media (max-width: 1024px) {
          .signup-container .sign-up .or-container.second {
            height: 1px;
            width: 80%; } }
        .signup-container .sign-up .or-container.second .or-text {
          display: none; }
          @media (max-width: 1024px) {
            .signup-container .sign-up .or-container.second .or-text {
              display: block; } }
        .signup-container .sign-up .or-container.second .or-line {
          width: 0;
          height: 167px;
          border: 0.5px solid #414141; }
          @media (max-width: 1024px) {
            .signup-container .sign-up .or-container.second .or-line {
              height: 1px;
              width: 80%; } }
      .signup-container .sign-up .or-container .or-line {
        width: 399px;
        height: 1px;
        border: 1px solid #414141; }
      .signup-container .sign-up .or-container .or-text {
        width: 50px;
        line-height: 0;
        text-align: center;
        font-size: 16px;
        color: #656565; }
    .signup-container .sign-up .redirection-container {
      height: 48px;
      font-size: 18px;
      color: #9e9e9e;
      display: flex;
      justify-content: center;
      margin: 80px 0;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 1024px) {
        .signup-container .sign-up .redirection-container {
          margin: 40px 0; } }
      .signup-container .sign-up .redirection-container .redirection-name {
        color: #9e9e9e;
        text-decoration-line: inherit; }
    .signup-container .sign-up .subscribe-checkbox-wrapper {
      width: 100%;
      padding: 0 3px; }
      .signup-container .sign-up .subscribe-checkbox-wrapper .checkbox {
        margin: 7px 0; }
        .signup-container .sign-up .subscribe-checkbox-wrapper .checkbox .input {
          width: 28px;
          height: 28px; }
        .signup-container .sign-up .subscribe-checkbox-wrapper .checkbox .checkbox-text {
          font-size: 16px;
          margin-left: 15px;
          color: #5d5d5d;
          line-height: 25px; }
          @media (max-width: 745px) {
            .signup-container .sign-up .subscribe-checkbox-wrapper .checkbox .checkbox-text {
              font-size: 14px; } }
    .signup-container .sign-up .register-button {
      margin: 0 0 10px;
      border-radius: 5px;
      background-color: #414141;
      color: #fff;
      font-size: 19px;
      width: 100%;
      height: 42px;
      cursor: default; }
      .signup-container .sign-up .register-button.enable {
        background-color: #ff740f;
        cursor: pointer; }
      @media (max-width: 745px) {
        .signup-container .sign-up .register-button {
          font-size: 16px; } }
    .signup-container .sign-up .form {
      display: flex;
      flex-direction: column; }
      .signup-container .sign-up .form .row {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        position: relative; }
        .signup-container .sign-up .form .row .phone-input {
          border-bottom: 1px solid #979797; }
        .signup-container .sign-up .form .row input {
          -webkit-box-shadow: 0 0 0 30px #242424 inset !important;
          -webkit-text-fill-color: white !important;
          outline: none;
          background-color: transparent !important;
          height: 53px;
          border-bottom: 1px solid #979797;
          font-size: 18px;
          position: relative; }
          .signup-container .sign-up .form .row input.email, .signup-container .sign-up .form .row input.password {
            width: 100%; }
          .signup-container .sign-up .form .row input.number-input {
            overflow: hidden; }
          @media (max-width: 745px) {
            .signup-container .sign-up .form .row input {
              font-size: 16px; } }
        .signup-container .sign-up .form .row input:-webkit-autofill,
        .signup-container .sign-up .form .row input:-webkit-autofill:focus,
        .signup-container .sign-up .form .row input:-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #242424 inset !important;
          -webkit-text-fill-color: white !important;
          -webkit-box-shadow: none;
          box-shadow: none;
          outline: none; }
        .signup-container .sign-up .form .row .react-tel-input input[type=tel] {
          width: 100%;
          border: none;
          border-bottom: 1px solid #979797;
          border-radius: 0;
          caret-color: white; }
        .signup-container .sign-up .form .row .react-tel-input .flag-dropdown {
          background-color: transparent;
          border: none; }
          .signup-container .sign-up .form .row .react-tel-input .flag-dropdown .selected-flag .arrow {
            width: 14px;
            height: 9px;
            background: url("~app/resources/assets/login/phone-down-arrow.svg") center no-repeat;
            border: none;
            transform: scale(0.7);
            top: 0;
            margin: 0; }
          .signup-container .sign-up .form .row .react-tel-input .flag-dropdown.open-dropdown .selected-flag, .signup-container .sign-up .form .row .react-tel-input .flag-dropdown:hover .selected-flag {
            width: 50px;
            background-color: transparent; }
        .signup-container .sign-up .form .row .react-tel-input .flag {
          transform: scale(1.4); }
        .signup-container .sign-up .form .row .react-tel-input .country-list {
          margin-top: 0; }
          @media (max-width: 1024px) {
            .signup-container .sign-up .form .row .react-tel-input .country-list {
              width: 200px; } }
      .signup-container .sign-up .form .error-message {
        color: #bd0000;
        font-style: normal;
        padding: 4px;
        margin-top: 0;
        margin-bottom: 6px;
        font-weight: 400;
        height: 17px;
        font-size: 12px;
        position: relative;
        align-items: baseline;
        text-align: left;
        width: 100%; }
    .signup-container .sign-up .social-login-container {
      margin-top: 0; }
      @media (max-width: 1024px) {
        .signup-container .sign-up .social-login-container {
          margin-top: 27px; } }
      .signup-container .sign-up .social-login-container .social-login-text {
        font-size: 18px; }
        @media (max-width: 745px) {
          .signup-container .sign-up .social-login-container .social-login-text {
            font-size: 16px; } }
  .signup-container .rodal-dialog {
    padding: 0; }
    .signup-container .rodal-dialog .rating-dialog-content .dialog-title {
      text-align: center;
      word-wrap: break-word;
      color: #606060;
      font-size: 22px;
      letter-spacing: 0.005em; }
    .signup-container .rodal-dialog .rating-dialog-content .dialog-ok-btn {
      border: 1px solid #606060;
      border-bottom: none;
      width: 100%;
      text-transform: none;
      color: #606060 !important;
      padding: 0;
      margin: 0 0 -17px !important;
      border-radius: 0; }
      .signup-container .rodal-dialog .rating-dialog-content .dialog-ok-btn:hover {
        background: #ff740f;
        color: white; }

@media screen and (max-width: 768px) {
  .Recaptcha > div > div > div {
    transform: scale(0.9);
    margin-left: -20px; } }

@media screen and (max-width: 320px) {
  .Recaptcha > div > div > div {
    transform: scale(0.8);
    margin-left: -40px; } }

@media screen and (max-width: 275px) {
  .Recaptcha > div > div > div {
    transform: scale(0.75);
    margin-left: -48px; } }

@media screen and (max-width: 280px) {
  .Recaptcha > div > div > div {
    transform: scale(0.7); } }

@media screen and (max-width: 768px) {
  .rtl .Recaptcha > div > div > div {
    transform: scale(0.88);
    margin-right: -20px !important; } }

@media screen and (max-width: 320px) {
  .rtl .Recaptcha > div > div > div {
    transform: scale(0.8);
    margin-right: -39px !important; } }

@media screen and (max-width: 275px) {
  .rtl .Recaptcha > div > div > div {
    transform: scale(0.75);
    margin-right: -46px !important; } }

@media screen and (max-width: 280px) {
  .rtl .Recaptcha > div > div > div {
    transform: scale(0.7); } }

@media (max-width: 768px) {
  .signup-container .sign-up .register-container .input-container .email-button, .signup-container .sign-up .register-container .input-container .mobile-button {
    min-width: 241px;
    width: 100%; }
  .social-login-container .login-facebook .social-login-text, .social-login-container .login-apple .social-login-text, .social-login-container .login-twitter .social-login-text {
    width: 33%; }
  .social-login-container .login-facebook .apple-login-text, .social-login-container .login-apple .apple-login-text, .social-login-container .login-twitter .apple-login-text {
    width: 20%; } }

@media (max-width: 275px) {
  .app-body[dir="rtl"] .signup-container .sign-up .login-facebook .social-login-text, .app-body[dir="rtl"] .signup-container .sign-up .login-apple .social-login-text, .app-body[dir="rtl"] .signup-container .sign-up .login-twitter .social-login-text {
    margin: 0 12px 0 0 !important; }
  .social-login-container .login-facebook .apple-login-text, .social-login-container .login-apple .apple-login-text, .social-login-container .login-twitter .apple-login-text {
    margin: 0 0; }
  .social-login-container .login-facebook .social-login-text, .social-login-container .login-apple .social-login-text, .social-login-container .login-twitter .social-login-text {
    width: 0; }
  .social-login-container .login-facebook .apple-login-text, .social-login-container .login-apple .apple-login-text, .social-login-container .login-twitter .apple-login-text {
    width: 65%;
    margin: 0 2px !important; }
  .social-login-container .login-facebook .apple-login-text, .social-login-container .login-apple .apple-login-text, .social-login-container .login-twitter .apple-login-text {
    margin: 0 -6px !important; }
  .rtl .app-body[dir="rtl"] .signup-container .sign-up .login-facebook .social-login-text, .app-body[dir="rtl"] .signup-container .sign-up .login-apple .social-login-text, .app-body[dir="rtl"] .signup-container .sign-up .login-twitter .social-login-text {
    margin: 0 12px 0 0 !important; }
  .rtl .social-login-container .login-facebook .apple-login-text, .social-login-container .login-apple .apple-login-text, .social-login-container .login-twitter .apple-login-text {
    margin: 0 4px !important; } }

@media (max-width: 1024px) {
  .social-login-container .login-apple {
    margin-top: -18px; }
  .rtl.social-login-container .login-facebook .apple-login-text, .social-login-container .login-apple .apple-login-text, .social-login-container .login-twitter .apple-login-text {
    margin: 0 15px; } }
