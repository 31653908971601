/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
@import url("https://fonts.googleapis.com/css2?family=Changa:wght@200;300;400;500;600&display=swap");
.app-body[dir="rtl"] .dropdown-bottom .dropdown-bottom-right {
  right: auto;
  left: 0; }

.Toastify__toast {
  border-radius: 10px; }

.dropdown-bottom {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  min-height: 295px;
  margin-bottom: 20px;
  padding-left: 60px;
  padding-top: 50px; }
  .dropdown-bottom .rodal-dialog {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .dropdown-bottom .rodal-dialog .rating-dialog-content .dialog-title {
      text-align: center;
      margin: 30px auto !important;
      color: #606060;
      font-size: 22px;
      letter-spacing: 0.005em; }
    .dropdown-bottom .rodal-dialog .rating-dialog-content .rating-container {
      text-align: center; }
      .dropdown-bottom .rodal-dialog .rating-dialog-content .rating-container .star {
        margin: 5px;
        display: inline-block;
        background-size: contain;
        height: 50px;
        width: 50px;
        content: "";
        background-repeat: no-repeat; }
      .dropdown-bottom .rodal-dialog .rating-dialog-content .rating-container .dv-star-rating-full-star .star {
        background-image: url("~app/resources/assets/thumbnail/ic-star.svg"); }
      .dropdown-bottom .rodal-dialog .rating-dialog-content .rating-container .dv-star-rating-empty-star .star {
        background-image: url("~app/resources/assets/rating/empty-star.png"); }
    .dropdown-bottom .rodal-dialog .rating-dialog-actions .dialog-ok-btn {
      border: 1px solid #606060;
      width: 100%;
      text-transform: none;
      color: #606060 !important;
      padding: 0;
      margin: 0 0 -17px !important;
      border-radius: 0;
      min-height: inherit;
      margin: 0 !important;
      text-decoration: none;
      letter-spacing: 0.01em;
      display: inline-block;
      align-items: center;
      text-align: center;
      font-size: 14px;
      height: 50px; }
      .dropdown-bottom .rodal-dialog .rating-dialog-actions .dialog-ok-btn:hover {
        background: #ff740f;
        color: white; }
  .dropdown-bottom .dropdown-bottom-left {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 45%; }
    .dropdown-bottom .dropdown-bottom-left .dropdown-info {
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
      .dropdown-bottom .dropdown-bottom-left .dropdown-info > div {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center; }
        @media (max-width: 1240px) {
          .dropdown-bottom .dropdown-bottom-left .dropdown-info > div {
            align-items: flex-start; } }
      .dropdown-bottom .dropdown-bottom-left .dropdown-info .title-info {
        font-size: 25px;
        font-weight: 700px; }
      .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-extra-info {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start; }
        .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-extra-info .rating {
          display: -ms-flexbox;
          display: -webkit-box;
          display: flex;
          -ms-flex-align: center;
          -webkit-box-align: center;
          align-items: center;
          cursor: pointer; }
          .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-extra-info .rating .ic-star {
            background: url("~app/resources/assets/thumbnail/ic-star.svg");
            width: 17px;
            height: 16px;
            display: inline-block;
            margin: 0 5px 3px -3px; }
          .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-extra-info .rating:after {
            content: "|";
            padding: 0 5px;
            position: relative;
            bottom: 2px;
            color: #838383; }
        .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-extra-info .age-rating {
          margin-right: 15px; }
      .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-actors-genres {
        font-weight: 700;
        flex-wrap: wrap; }
        .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-actors-genres .cast,
        .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-actors-genres .genres,
        .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-actors-genres .label {
          height: 25px;
          line-height: 25px;
          margin: 0 5px 0 0;
          font-size: 0.875em; }
        .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-actors-genres .label {
          color: rgba(193, 193, 193, 0.7); }
        .dropdown-bottom .dropdown-bottom-left .dropdown-info .dropdown-actors-genres a {
          margin-top: 1px;
          padding: 0; }
    .dropdown-bottom .dropdown-bottom-left .actions {
      font-size: 1.125em;
      width: 400px;
      max-width: 350px;
      margin-bottom: 30px;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-direction: row;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row; }
      @media (max-width: 1240px) {
        .dropdown-bottom .dropdown-bottom-left .actions {
          width: 100%; } }
      .dropdown-bottom .dropdown-bottom-left .actions .fb-share,
      .dropdown-bottom .dropdown-bottom-left .actions .playlist-btn,
      .dropdown-bottom .dropdown-bottom-left .actions .share-btn,
      .dropdown-bottom .dropdown-bottom-left .actions .twitter-share {
        width: 50px;
        height: 86px;
        font-size: 12px;
        margin: 0 10px;
        padding: 0;
        color: #feffff;
        cursor: pointer; }
        .dropdown-bottom .dropdown-bottom-left .actions .fb-share:hover,
        .dropdown-bottom .dropdown-bottom-left .actions .playlist-btn:hover,
        .dropdown-bottom .dropdown-bottom-left .actions .share-btn:hover,
        .dropdown-bottom .dropdown-bottom-left .actions .twitter-share:hover {
          background-color: transparent; }
          .dropdown-bottom .dropdown-bottom-left .actions .fb-share:hover .icon,
          .dropdown-bottom .dropdown-bottom-left .actions .playlist-btn:hover .icon,
          .dropdown-bottom .dropdown-bottom-left .actions .share-btn:hover .icon,
          .dropdown-bottom .dropdown-bottom-left .actions .twitter-share:hover .icon {
            border: 1px solid #ff6d02;
            border-radius: 23px; }
        .dropdown-bottom .dropdown-bottom-left .actions .fb-share .icon,
        .dropdown-bottom .dropdown-bottom-left .actions .playlist-btn .icon,
        .dropdown-bottom .dropdown-bottom-left .actions .share-btn .icon,
        .dropdown-bottom .dropdown-bottom-left .actions .twitter-share .icon {
          width: 46px;
          height: 46px;
          vertical-align: middle;
          top: 0; }
      .dropdown-bottom .dropdown-bottom-left .actions .expanded-share {
        display: flex; }
  .dropdown-bottom .dropdown-bottom-right {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    width: 50%;
    padding-top: 50px;
    position: absolute;
    min-height: 430px;
    top: 0;
    right: 0; }
    .dropdown-bottom .dropdown-bottom-right .play-button {
      position: absolute;
      top: 50%;
      left: 47%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .dropdown-bottom .dropdown-bottom-right .play-button .icon {
        width: 115px;
        height: 115px; }
      .dropdown-bottom .dropdown-bottom-right .play-button:hover {
        background-color: transparent; }
  .dropdown-bottom .dropdown-actors-genres a {
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;
    text-decoration: none; }
    .dropdown-bottom .dropdown-actors-genres a:focus, .dropdown-bottom .dropdown-actors-genres a:hover, .dropdown-bottom .dropdown-actors-genres a:visited {
      text-decoration: none;
      -webkit-text-decoration-color: transparent;
      text-decoration-color: transparent; }
  .dropdown-bottom .dropdown-actors-genres a {
    color: #fff; }
    .dropdown-bottom .dropdown-actors-genres a:hover {
      text-decoration: underline;
      cursor: pointer; }

.details-container {
  z-index: 1; }

.program-banner-data {
  display: block;
  height: auto;
  background-color: transparent;
  border-radius: 6px;
  position: relative;
  height: 100%; }
  @media (max-width: 767px) {
    .program-banner-data {
      height: auto;
      margin: 0px 0px 0px 0px; } }
  .program-banner-data .rodal-dialog {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .program-banner-data .rodal-dialog .rating-dialog-content .dialog-title {
      text-align: center;
      margin: 30px auto !important;
      color: #606060;
      font-size: 22px;
      letter-spacing: 0.005em; }
    .program-banner-data .rodal-dialog .rating-dialog-content .rating-container {
      text-align: center; }
      .program-banner-data .rodal-dialog .rating-dialog-content .rating-container .star {
        margin: 5px;
        display: inline-block;
        background-size: contain;
        height: 50px;
        width: 50px;
        content: "";
        background-repeat: no-repeat; }
      .program-banner-data .rodal-dialog .rating-dialog-content .rating-container .dv-star-rating-full-star .star {
        background-image: url("~app/resources/assets/thumbnail/ic-star.svg"); }
      .program-banner-data .rodal-dialog .rating-dialog-content .rating-container .dv-star-rating-empty-star .star {
        background-image: url("~app/resources/assets/rating/empty-star.png"); }
    .program-banner-data .rodal-dialog .rating-dialog-actions .dialog-ok-btn {
      border: 1px solid #606060;
      width: 100%;
      text-transform: none;
      color: #606060 !important;
      padding: 0;
      margin: 0 0 -17px !important;
      border-radius: 0;
      min-height: inherit;
      margin: 0 !important;
      text-decoration: none;
      letter-spacing: 0.01em;
      display: inline-block;
      align-items: center;
      text-align: center;
      font-size: 14px;
      height: 50px; }
      .program-banner-data .rodal-dialog .rating-dialog-actions .dialog-ok-btn:hover {
        background: #ff740f;
        color: white; }
  .program-banner-data .banner-src {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%; }
    @media (max-width: 767px) {
      .program-banner-data .banner-src {
        display: none; } }
  .program-banner-data .banner-data {
    position: relative;
    z-index: 2; }
  @media (max-width: 767px) {
    .program-banner-data {
      height: auto; } }
  @media (max-width: 1024px) {
    .program-banner-data .bannermblscreen h2 {
      display: none; } }
  @media (max-width: 767px) {
    .program-banner-data .bannermblscreen h2 {
      text-align: left;
      font: normal normal bold 16px/12px Changa;
      letter-spacing: -0.38px;
      color: #757575;
      opacity: 1; } }
  @media (max-width: 767px) {
    .program-banner-data .bannermblscreen .mobile-image {
      height: 395px; } }
  .program-banner-data .bannermblscreen img {
    display: none;
    border-radius: 6px; }
    @media (max-width: 767px) {
      .program-banner-data .bannermblscreen img {
        display: block;
        width: 100%;
        height: 120%;
        top: 169px;
        left: 94px; } }
  @media (max-width: 767px) {
    .program-banner-data {
      background-image: none !important; } }
  .program-banner-data .banner-info-data {
    width: 45%;
    padding-top: 0px;
    padding-left: 80px;
    padding-right: 80px; }
    @media (max-width: 767px) {
      .program-banner-data .banner-info-data {
        width: 100%;
        margin-bottom: 30px;
        font: normal normal normal 14px/16px Changa;
        letter-spacing: 0px;
        color: #C0C0C0; } }
    .program-banner-data .banner-info-data .contentTitle {
      color: #FFFFFF;
      margin-top: 5px;
      padding-top: 0;
      font-weight: bold;
      padding-bottom: 0; }
      @media (max-width: 745px) {
        .program-banner-data .banner-info-data .contentTitle {
          font-size: 16px;
          margin-bottom: 0px;
          position: absolute;
          top: 22em;
          left: 50%;
          white-space: nowrap;
          transform: translateX(-50%); } }
    .program-banner-data .banner-info-data .cdtitleLogoContainer {
      display: flex;
      align-items: center; }
      @media (max-width: 745px) {
        .program-banner-data .banner-info-data .cdtitleLogoContainer {
          position: relative;
          bottom: 0em;
          justify-content: center; } }
    .program-banner-data .banner-info-data .contentTitleEnAR {
      width: auto;
      display: block;
      margin-top: 264px;
      font-size: 74px;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      font: normal normal bold 60px/21px Changa; }
    .program-banner-data .banner-info-data .cdtitleLogoImage {
      display: block;
      margin-top: 200px; }
      @media (max-width: 767px) {
        .program-banner-data .banner-info-data .cdtitleLogoImage {
          margin-top: -80px;
          max-width: 250px; } }
    .program-banner-data .banner-info-data h2 {
      font-size: 36px;
      color: #fff;
      margin-bottom: 15px;
      padding-top: 30px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 0; }
    .program-banner-data .banner-info-data .contentTitleEnCD {
      width: auto;
      display: block;
      margin-top: 240px;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      white-space: nowrap;
      font: normal normal bold 60px/21px Changa; }
      @media (max-width: 1440px) {
        .program-banner-data .banner-info-data .contentTitleEnCD {
          width: auto;
          display: block;
          margin-top: 231px;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
          white-space: nowrap;
          font: normal normal bold 60px / 21px Changa; } }
      @media (max-width: 745px) {
        .program-banner-data .banner-info-data .contentTitleEnCD {
          font-size: 32px;
          margin-bottom: 0px;
          position: absolute;
          top: 9em;
          left: 50%;
          /* white-space: nowrap; */
          transform: translateX(-50%);
          color: white;
          min-height: 100px;
          /* background: red; */
          width: 90%;
          text-align: center;
          line-height: 40px;
          margin: 0; } }
    .program-banner-data .banner-info-data .content-details-synopsis {
      color: #FFFFFF;
      width: 82%;
      margin-top: 36px;
      font-family: 'changa-Regular';
      font-size: 18px;
      width: 496px;
      line-height: 25px; }
      @media (max-width: 1440px) {
        .program-banner-data .banner-info-data .content-details-synopsis {
          color: #FFFFFF;
          overflow: hidden;
          font-family: "Changa-Regular";
          line-height: 25px;
          width: 518px; } }
      @media (max-width: 767px) {
        .program-banner-data .banner-info-data .content-details-synopsis {
          font: normal normal normal 14px / 16px Changa;
          width: 338px;
          position: relative;
          top: 8em;
          left: -60px;
          letter-spacing: 0px;
          color: #C0C0C0;
          opacity: 1;
          font-family: 'changa-Regular';
          right: -65px;
          z-index: 3; } }
    .program-banner-data .banner-info-data p {
      color: #fff;
      font: normal normal normal 20px/27px Changa;
      line-height: 20px;
      font-weight: 700;
      font-size: 20px;
      padding: 60px 2px 7px; }
      @media (max-width: 1023px) and (min-width: 768px) {
        .program-banner-data .banner-info-data p {
          font-size: 10px !important; } }
      @media (max-width: 767px) {
        .program-banner-data .banner-info-data p {
          line-height: 16px;
          font-size: 14px; } }
      .program-banner-data .banner-info-data p span {
        color: white;
        /* font-weight: 500; */
        font-family: Changa;
        /* font: normal normal normal 20px / 27px Changa; */
        letter-spacing: 0px;
        color: #FFFFFF;
        /* font-size: larger; */ }
      .program-banner-data .banner-info-data p a {
        color: white;
        font: normal normal normal 20px/27px Changa;
        font-weight: 500; }
        @media (max-width: 1023px) and (min-width: 768px) {
          .program-banner-data .banner-info-data p a {
            font-size: 10px !important; } }
    .program-banner-data .banner-info-data .program-info {
      margin-top: 14px;
      z-index: 3; }
      .program-banner-data .banner-info-data .program-info p {
        margin: 0;
        padding: 0;
        font: normal normal normal 20px/27px Changa !important;
        color: #FFFFFF; }
        @media (max-width: 1023px) and (min-width: 768px) {
          .program-banner-data .banner-info-data .program-info p {
            font-size: 10px !important; } }
        @media (max-width: 767px) {
          .program-banner-data .banner-info-data .program-info p {
            font: normal normal normal 20px/20px Changa !important; } }
        .program-banner-data .banner-info-data .program-info p:first-child {
          margin-bottom: 5px;
          width: 496px; }
          @media (max-width: 767px) {
            .program-banner-data .banner-info-data .program-info p:first-child {
              width: auto; } }
  .program-banner-data .micro-elements-info {
    margin-top: 38px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    margin-left: 3px;
    margin-right: 16px; }
    @media (max-width: 767px) {
      .program-banner-data .micro-elements-info {
        margin-top: 20px;
        margin-bottom: 0px; } }
    .program-banner-data .micro-elements-info .right-mbldata {
      margin-left: auto;
      display: flex;
      z-index: 1; }
    .program-banner-data .micro-elements-info .addListEn, .program-banner-data .micro-elements-info .addArList {
      display: none; }
    .program-banner-data .micro-elements-info .rate-block {
      width: 52px;
      height: 30px;
      border-radius: 30px;
      background-color: #2e2e2e;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      color: white;
      display: flex;
      font-size: 12px;
      cursor: pointer; }
      .program-banner-data .micro-elements-info .rate-block img {
        height: 14px;
        margin-right: 5px; }
      @media (max-width: 767px) {
        .program-banner-data .micro-elements-info .rate-block {
          padding: 5px; } }
    .program-banner-data .micro-elements-info .content-rateblock:hover {
      background-color: #EE7623;
      color: white; }
    .program-banner-data .micro-elements-info .g-data {
      margin-right: 15px;
      text-decoration: none;
      transition: all 0.3s ease;
      font-family: 'Changa', sans-serif;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      font-size: 12px; }
      .program-banner-data .micro-elements-info .g-data .duration-data {
        font-weight: 700; }
        @media (max-width: 767px) {
          .program-banner-data .micro-elements-info .g-data .duration-data {
            display: none; } }
      .program-banner-data .micro-elements-info .g-data .agestamp {
        font-weight: 700; }
      .program-banner-data .micro-elements-info .g-data:hover {
        opacity: 0.7; }
      .program-banner-data .micro-elements-info .g-data img {
        height: 30px;
        margin-right: 10px; }
  .program-banner-data .play-btn {
    margin: 2px;
    width: 13em;
    height: 40px;
    border-radius: 10px;
    background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #EC1C24 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #EE7623 0%, #EC1C24 100%) 0% 0% no-repeat padding-box;
    line-height: 30px;
    text-align: center;
    padding: 0 20px;
    border: 0;
    color: white;
    z-index: 1; }

@media (max-width: 767px) {
  .rtl .content-details-synopsis {
    z-index: 0 !important; } }

.detailsPage-actions {
  display: flex;
  align-items: center; }

@media (min-width: 767px) {
  .thumbscroll-block .trailercard.large-card {
    display: flex; } }

.thumbscroll-block .trailercard.large-card .indithumb-card {
  width: auto;
  display: block; }
  .thumbscroll-block .trailercard.large-card .indithumb-card .thumb-media .thumb-img-src {
    width: 100%; }
  @media (max-width: 767px) {
    .thumbscroll-block .trailercard.large-card .indithumb-card .thumb-media {
      margin-bottom: 5px; } }
  .thumbscroll-block .trailercard.large-card .indithumb-card p {
    padding-top: 0;
    margin-top: 0; }
    @media (max-width: 767px) {
      .thumbscroll-block .trailercard.large-card .indithumb-card p {
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px; } }
  @media (max-width: 767px) {
    .thumbscroll-block .trailercard.large-card .indithumb-card {
      width: 100%; } }

.thumbscroll-block .scroll-pills {
  margin-bottom: 20px;
  display: flex; }
  @media (max-width: 767px) {
    .thumbscroll-block .scroll-pills {
      margin-right: 15px;
      margin-left: 15px; } }
  .thumbscroll-block .scroll-pills ul {
    padding: 0;
    margin: 0;
    display: flex; }
    .thumbscroll-block .scroll-pills ul li {
      list-style-type: none;
      margin-right: 15px; }
      .thumbscroll-block .scroll-pills ul li div.link-item {
        font-family: Changa;
        cursor: pointer;
        color: white;
        position: relative;
        text-decoration: none; }
        .thumbscroll-block .scroll-pills ul li div.link-item.active:after {
          content: "";
          background-color: #ff6d02;
          width: 90%;
          height: 3px;
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          margin: 0 auto; }

.thumbscroll-block .thumbdata-block {
  padding-bottom: 30px; }
  .thumbscroll-block .thumbdata-block .cardlist-data {
    display: flex; }
    @media (max-width: 767px) {
      .thumbscroll-block .thumbdata-block .cardlist-data {
        display: block; } }
  @media (max-width: 767px) {
    .thumbscroll-block .thumbdata-block {
      display: block;
      margin: 15px 15px 0px 15px; } }

.thumbscroll-block .indithumb-card {
  cursor: pointer;
  margin-right: 18px;
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .thumbscroll-block .indithumb-card {
      width: 100%;
      display: flex; } }
  .thumbscroll-block .indithumb-card .thumb-media {
    position: relative;
    margin-bottom: 10px;
    border-radius: 4px; }
    @media (max-width: 767px) {
      .thumbscroll-block .indithumb-card .thumb-media {
        margin-bottom: 0;
        margin-right: 10px; } }
    @media (max-width: 767px) {
      .thumbscroll-block .indithumb-card .thumb-media .thumb-img-src {
        width: 100px; } }
    .thumbscroll-block .indithumb-card .thumb-media .thumb-img-src img {
      width: 100%;
      border-radius: 4px; }
    .thumbscroll-block .indithumb-card .thumb-media .thumb-img-data {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 5px; }
      .thumbscroll-block .indithumb-card .thumb-media .thumb-img-data .count {
        color: white;
        font-family: Changa;
        font-size: 14px; }
      .thumbscroll-block .indithumb-card .thumb-media .thumb-img-data .duration {
        color: #929090;
        font-family: Changa;
        font-size: 14px;
        margin-left: auto; }
  .thumbscroll-block .indithumb-card p {
    color: #c0c0c0;
    font-size: 12px;
    line-height: 12px;
    font-family: Changa; }
    @media (max-width: 767px) {
      .thumbscroll-block .indithumb-card p {
        font-size: 14px;
        line-height: 14px;
        font-weight: 300;
        margin-top: 5px; } }

.thumbscroll-block .indithumb-card-trailer {
  cursor: pointer; }
  .thumbscroll-block .indithumb-card-trailer .icon {
    width: 20px;
    height: 20px;
    position: relative;
    top: 15px; }
  .thumbscroll-block .indithumb-card-trailer .carousel-image :last-child {
    width: 223px;
    height: 119px; }
  @media (max-width: 767px) {
    .thumbscroll-block .indithumb-card-trailer {
      width: 100%;
      display: flex; } }
  .thumbscroll-block .indithumb-card-trailer .thumb-media {
    position: relative;
    margin-bottom: 10px;
    border-radius: 4px; }
    @media (max-width: 767px) {
      .thumbscroll-block .indithumb-card-trailer .thumb-media {
        margin-bottom: 0;
        margin-right: 10px; } }
    @media (max-width: 767px) {
      .thumbscroll-block .indithumb-card-trailer .thumb-media .thumb-img-src {
        width: 100px; } }
    .thumbscroll-block .indithumb-card-trailer .thumb-media .thumb-img-src img {
      width: 100%;
      border-radius: 4px; }
    .thumbscroll-block .indithumb-card-trailer .thumb-media .thumb-img-data {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      padding: 5px; }
      .thumbscroll-block .indithumb-card-trailer .thumb-media .thumb-img-data .count {
        color: white;
        font-family: Changa;
        font-size: 14px; }
      .thumbscroll-block .indithumb-card-trailer .thumb-media .thumb-img-data .duration {
        color: #929090;
        font-family: Changa;
        font-size: 14px;
        margin-left: auto; }
  .thumbscroll-block .indithumb-card-trailer p {
    color: #c0c0c0;
    font-size: 12px;
    line-height: 12px;
    font-family: Changa; }
    @media (max-width: 767px) {
      .thumbscroll-block .indithumb-card-trailer p {
        font-size: 14px;
        line-height: 14px;
        font-weight: 300;
        margin-top: 5px; } }

.season-dropdown {
  margin-left: auto; }
  .season-dropdown .select-box {
    cursor: pointer;
    padding: 0 15px;
    height: 30px;
    border-radius: 8px;
    background-color: #2e2e2e;
    display: flex;
    align-items: center; }
    .season-dropdown .select-box p {
      color: white;
      padding: 0 10px 0 0;
      margin: 0;
      line-height: normal;
      font-family: Changa; }

.d-flex {
  display: flex; }

.mbl-hide {
  padding-left: 30px;
  padding-right: 20px; }
  @media (max-width: 1023px) and (min-width: 768px) {
    .mbl-hide {
      padding-left: 10px !important;
      padding-right: 10px !important; } }
  @media (max-width: 767px) {
    .mbl-hide {
      display: none; } }

.show-desktop {
  display: none; }
  @media (max-width: 767px) {
    .show-desktop {
      display: block;
      text-align: center;
      margin-top: -11%;
      margin-right: 10px; } }

.show-desktopAr {
  display: none; }
  @media (max-width: 767px) {
    .show-desktopAr {
      display: block;
      text-align: center;
      margin-top: -11%;
      margin-left: 8em; } }

@media (max-width: 767px) {
  .app-body.rtl .bannermblscreen {
    height: 395px; }
    .app-body.rtl .bannermblscreen h2 {
      text-align: right;
      font: normal normal bold 16px/12px Changa;
      letter-spacing: -0.38px;
      color: #757575;
      opacity: 1; } }

.app-body.rtl .program-banner-data .micro-elements-info .rate-block img {
  margin-left: 5px;
  margin-right: 0; }

.app-body.rtl .program-banner-data .micro-elements-info .right-mbldata {
  margin-right: 80px; }
  @media (max-width: 767PX) {
    .app-body.rtl .program-banner-data .micro-elements-info .right-mbldata {
      margin-right: 230px;
      margin-left: inherit;
      z-index: 1; } }

.app-body.rtl .program-banner-data .micro-elements-info .g-data img {
  margin-right: 0;
  margin-left: 5px; }

.app-body.rtl .thumbscroll-block .indithumb-card .thumb-media .thumb-img-data .duration {
  margin-right: auto;
  margin-left: inherit; }

.app-body.rtl .scroll-pills .season-dropdown {
  margin-right: auto;
  margin-left: inherit; }

.app-body.rtl .season-dropdown .select-box p {
  padding: 0 0 0 10px; }

.app-body.rtl .thumbscroll-block .indithumb-card {
  margin-right: 0; }

.app-body.rtl .thumbscroll-block .indithumb-card {
  margin-right: 0;
  margin-left: 18px; }

@media (max-width: 767px) {
  .app-body.rtl .thumbscroll-block .indithumb-card .thumb-media {
    margin-right: 0;
    margin-left: 15px; } }

.app-body.rtl .thumbscroll-block .scroll-pills ul li {
  margin-left: 15px;
  margin-right: 0; }

.fb-share,
.playlist-btn,
.share-btn,
.twitter-share {
  width: 30px;
  height: 30px;
  font-size: 12px;
  margin: 0 14px;
  padding: 0;
  color: #feffff;
  cursor: pointer; }
  .fb-share:hover,
  .playlist-btn:hover,
  .share-btn:hover,
  .twitter-share:hover {
    background-color: transparent; }
    .fb-share:hover .icon,
    .playlist-btn:hover .icon,
    .share-btn:hover .icon,
    .twitter-share:hover .icon {
      border: 1px solid #ff6d02;
      border-radius: 23px; }
  .fb-share .icon,
  .playlist-btn .icon,
  .share-btn .icon,
  .twitter-share .icon {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    top: 0; }

.expanded-share {
  display: flex;
  font-family: 'Changa', sans-serif;
  font-size: 12px; }

.program-banner-space {
  margin-top: 0 !important;
  width: 100%; }

.staricon {
  background-image: url(../../../resources/assets/starpath.svg);
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 3px;
  margin-left: 3px; }

.content-rateblock:hover .staricon {
  background-image: url(../../../resources/assets/starwhite.svg);
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 3px; }

.shareonhover {
  background-image: url(../../../resources/assets/newslider/shareicon.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 15px;
  opacity: 1;
  margin-right: 3px;
  margin-left: 3px; }

.addonhover {
  background-image: url(../../../resources/assets/newslider/like_default.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 20px;
  opacity: 1;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer; }

.removePlaylistIcon {
  background-image: url(../../../resources/assets/newslider/like_filled.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 20px;
  opacity: 1;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer; }

.program-banner-data .micro-elements-info .g-data:hover {
  opacity: 1; }

.rtl .program-banner-data .micro-elements-info .g-data {
  margin-right: 10px;
  transform: translateX(50%); }

.rtl .program-banner-data .banner-info-data p {
  font: normal normal normal 20px/27px Changa; }
  @media (max-width: 767px) {
    .rtl .program-banner-data .banner-info-data p {
      font: normal normal normal 14px/16px Changa; } }

.rtl .program-banner-data .banner-info-data p a {
  font: normal normal normal 20px/27px Changa;
  font-weight: 100; }
  @media (max-width: 767px) {
    .rtl .program-banner-data .banner-info-data p a {
      font: normal normal normal 14px/16px Changa; } }

.rtl .program-banner-data .micro-elements-info .rate-block {
  margin-right: 0; }

.rtl .program-banner-data .micro-elements-info .g-data img {
  transform: scaleX(-1); }

.new-banner-CDcontentimage {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  /* margin: 25% 0% -33% 0%; */
  position: relative;
  top: 13em; }

.new-banner-contentype {
  color: white;
  margin: 0;
  padding: 10px;
  text-transform: uppercase;
  font: normal normal medium 20px / 21px Changa; }

@media (max-width: 767px) {
  .new-banner-CDcontentimage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -265px auto 117px auto;
    text-align: center;
    top: 4em; }
  .new-banner-contentype {
    white-space: nowrap; }
  .title-images {
    filter: brightness(0) invert(1);
    height: 12px; }
  .thumbscroll-block {
    background: #0F1822;
    margin-top: 111px; } }

.title-images {
  filter: brightness(0) invert(1); }

@media (max-width: 767px) {
  .showPopup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(27, 27, 27, 0.8); }
  .showpopup-icons-wrapper {
    position: absolute;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 63px; }
    .showpopup-icons-wrapper .fb-share {
      margin: 13px 13px 13px -53px;
      display: flex;
      align-items: center; }
      .showpopup-icons-wrapper .fb-share span {
        padding: 10px;
        font-size: 16px;
        font-family: 'Changa'; }
    .showpopup-icons-wrapper .twitter-share {
      margin: 13px 13px 30px -51px;
      display: flex;
      align-items: center; }
      .showpopup-icons-wrapper .twitter-share span {
        padding: 10px;
        font-size: 16px;
        font-family: 'Changa'; }
    .showpopup-icons-wrapper .more-options span {
      color: white;
      font-size: 16px;
      font-family: 'Changa'; }
    .showpopup-icons-wrapper .more-options .close-icon {
      background-image: url("../../../resources/assets/remove.svg");
      height: 33px;
      width: 33px;
      background-size: 33px;
      margin: 15px auto; } }

@media (max-width: 767px) {
  .rtl .showPopup .showpopup-icons-wrapper {
    bottom: 115px; }
    .rtl .showPopup .showpopup-icons-wrapper .fb-share {
      margin: 13px 7px 13px 85px !important; }
    .rtl .showPopup .showpopup-icons-wrapper .twitter-share {
      margin: 13px 7px 30px 85px !important; } }

@media (max-width: 767px) {
  .rtl .banner-data .play-btn {
    z-index: 1; }
  .program-info {
    font: normal normal normal 14px / 16px Changa;
    letter-spacing: 0px;
    color: #606060;
    opacity: 1;
    overflow-wrap: break-word;
    margin-top: 14px;
    z-index: 3;
    position: relative;
    top: 115px;
    left: -57px;
    width: 330px; }
  .rtl .program-info {
    font: normal normal normal 14px / 16px Changa;
    font: normal normal normal 14px / 16px Changa;
    letter-spacing: 0px;
    color: #606060;
    opacity: 1;
    overflow-wrap: break-word;
    margin-top: 14px;
    z-index: 3;
    position: relative;
    top: 115px;
    left: 0px;
    width: 330px;
    right: -65px; } }

.gradient3 {
  background: transparent linear-gradient(180deg, rgba(15, 24, 34, 0) 47%, #0f1822 70%) 73% 24% no-repeat padding-box;
  width: 100%;
  height: 457px;
  top: 11em;
  position: absolute;
  pointer-events: none;
  z-index: 3; }

.rtl .gradient3 {
  background: transparent linear-gradient(180deg, rgba(15, 24, 34, 0) 47%, #0f1822 70%) 73% 24% no-repeat padding-box;
  width: 100%;
  height: 458px;
  top: 11em;
  position: absolute;
  pointer-events: none;
  z-index: 0;
  left: 0px; }

@media (max-width: 767px) {
  .firstFolderBanner {
    margin: 2px;
    padding: 10px;
    top: 0em;
    z-index: 1;
    height: 70px; } }

@media (max-width: 1023px) and (min-width: 768px) {
  .content-details-synopsis {
    width: 190px !important;
    font-size: 10px !important;
    line-height: 15px !important;
    margin-top: -29px !important; }
  .rtl .content-details-synopsis {
    width: 190px !important;
    font-size: 10px !important;
    line-height: 15px !important;
    margin-top: 10px !important; }
  .contentTitle {
    white-space: nowrap !important;
    font-size: 10px !important; }
  .cdtitleLogoImage {
    height: 70px !important;
    width: 180px !important; }
  .new-banner-CDcontentimage {
    filter: brightness(0) invert(1);
    height: 10px !important; }
  .new-banner-contentype {
    font-size: 10px !important; }
  .play-btn {
    width: 144px !important;
    height: 30px !important;
    font-size: 10px !important;
    margin-top: 41px; }
  .banner-info-data {
    padding-left: 30px !important;
    padding-right: 35px !important; } }

@media (max-width: 1366px) and (min-width: 768px) and (orientation: landscape) {
  .cdtitleLogoImage {
    max-width: 220px !important;
    object-fit: contain; }
  .rtl .cdtitleLogoImage {
    max-width: 220px !important;
    object-fit: contain; } }

@media (max-width: 1366px) and (min-width: 768px) and (orientation: portrait) {
  .cdtitleLogoImage {
    max-width: 220px !important;
    object-fit: contain; }
  .rtl .cdtitleLogoImage {
    max-width: 220px !important;
    object-fit: contain; } }
