/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir=rtl] .sidemenu {
  right: -456px;
  left: inherit; }

.app-body[dir=rtl] .sidemenu.show {
  right: 0; }

.sidemenu.show.left {
  left: unset; }

.sidemenu {
  width: 456px;
  position: fixed;
  z-index: 999;
  top: 0;
  background-color: #282828;
  overflow: hidden;
  overflow-y: auto;
  transition: all 0.2s;
  padding-top: 60px; }
  .sidemenu.left {
    left: -456px;
    width: 193px; }
  .sidemenu.right {
    right: -456px; }
  .sidemenu.show {
    z-index: 999;
    height: 303px;
    width: 193px;
    position: fixed;
    top: 6em;
    border-radius: 20px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 14px;
    z-index: 100; }
    .sidemenu.show.left {
      left: unset;
      right: 50px; }
      @media (max-width: 1440px) {
        .sidemenu.show.left {
          left: unset;
          right: 10px; } }
      @media (max-width: 745px) {
        .sidemenu.show.left {
          z-index: 999; } }
    .sidemenu.show.right {
      right: 0; }
  .sidemenu span {
    text-decoration: none;
    color: white;
    display: block;
    font-size: 15px;
    font-family: 'changa-regular'; }
    .sidemenu span:hover {
      cursor: pointer; }
  .sidemenu .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px; }

@media (max-width: 767px) {
  .sidemenu.show {
    opacity: 1;
    height: 103vh;
    width: 100vw;
    position: fixed;
    top: 4em;
    border-radius: 20px;
    background: linear-gradient(to bottom, #EC1C24 -62%, #0F1822 54%);
    margin-top: -5em; }
    .sidemenu.show.left {
      left: 0; }
    .sidemenu.show.right {
      right: 0; } }
