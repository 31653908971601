/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 @import "app/resources/styles/variables.scss";

 @font-face {
     font-family: "Droid Sans";
     font-display: swap;
     src: url("~app/resources/fonts/DroidSans.woff2") format("woff2"),
         url("~app/resources/fonts/DroidSans.woff") format("woff"),
         url("~app/resources/fonts/DroidSans.ttf") format("truetype");
 }
 
 @font-face {
     font-family: DroidSans-Bold;
     font-display: swap;
     src: url("~app/resources/fonts/DroidSans-Bold.woff2") format("woff2"),
         url("~app/resources/fonts/DroidSans-Bold.woff") format("woff"),
         url("~app/resources/fonts/DroidSans-Bold.ttf") format("truetype");
 } 

 
 @font-face {
     font-family: DroidKufi-Regular;
     font-display: swap;
     src: url("~app/resources/fonts/DroidKufi-Regular.ttf") format("truetype");
 }
 
 @font-face {
     font-family: DroidKufi-Bold;
     font-display: swap;
     src: url("~app/resources/fonts/DroidKufi-Bold.ttf") format("truetype");
 }
 
 @font-face {
     font-family: "changa";
     font-display: swap;
     src: url("~app/resources/fonts/Changa-Medium.ttf") format("truetype");
 }
 
 @font-face {
     font-family: changa-Regular;
     font-display: swap;
     src: url("~app/resources/fonts/Changa-Regular.ttf") format("truetype");
 }
 
 @font-face {
     font-family: changa-medium;
     font-display: swap;
     src: url("~app/resources/fonts/Changa-Medium.ttf") format("truetype");
 }
 
 #root,
 html {
     height: 100%;
 }
 body {
     margin: 0;
     padding: 0;
     height: 100%;
     min-width: 300px;
     font-family: "Droid Sans", Roboto, sans-serif;
     -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
 
     a,
     html,
     md-bottom-sheet .md-subheader,
     select {
         font-family: DroidKufi-Regular, Roboto, sans-serif;
         // &:focus {
         //   background: $tabIndexFocusBackground;
         // }
     }
 
     button,
     input,
     textarea {
        //  font-family: "Neo Sans Arabic";
     }
 
     .app-body[dir="rtl"] {
         font-family: DroidKufi-Regular, Roboto, sans-serif;
 
         a,
         html,
         md-bottom-sheet .md-subheader,
         p,
         select {
             font-family: DroidKufi-Regular, Roboto, sans-serif;
         }
     }
 }
 
 .scroll-bar {
     &::-webkit-scrollbar {
         width: 18px;
         max-height: 300px;
         background-color: $scroll-background-color;
     }
 
     &::-webkit-scrollbar-track {
         -webkit-box-shadow: inset 0 0 6px $scoll-box-shadow-color;
         box-shadow: inset 0 0 6px $scoll-box-shadow-color;
         border-radius: 10px;
         background-color: $scroll-background-color;
     }
 
     &::-webkit-scrollbar-thumb {
         border-radius: 15px;
         background-color: $scroll-thumb-color;
         border: 4px solid $scroll-background-color;
     }
 }
 
 
 body {
     @extend .scroll-bar;
 }
 
 .app-body {
     overflow: hidden;
     -webkit-overflow-scrolling: touch;
 
     *,
     :after,
     :before {
         -webkit-box-sizing: border-box;
         box-sizing: border-box;
 
         outline: 0;
         font-weight: 400;
         -ms-touch-action: manipulation;
         touch-action: manipulation;
         -webkit-overflow-scrolling: touch;
     }
 }
 
 .gone {
     display: none;
 }
 
 .invisible,
 .hidden {
     visibility: hidden;
 }
 
 .visible {
     visibility: visible;
 }
 
 /*Device width pixel lesser than 745*/
//  @media only screen and (max-width: 745px) {}
 
 /*Device width pixel in between 360-450*/
//  @media only screen and (max-width: 450px) {}
 
 .latest-cookie-block {
     // text-align: left;
     width: 800px;
     margin: 0 auto;
     padding: 0 30px;
     max-height: 480px;
     overflow-y: auto;
 
     /* Scrollbar Styling */
     &::-webkit-scrollbar {
         width: 4px;
     }
 
     &::-webkit-scrollbar-track {
         background-color: #787880;
         -webkit-border-radius: 10px;
         border-radius: 10px;
     }
 
     &::-webkit-scrollbar-thumb {
         -webkit-border-radius: 10px;
         border-radius: 10px;
         background: rgba(255, 255, 255, .5);
     }
 
     @media (max-width: 1024px) {
         width: 100%;
     }
 
     p {
         line-height: 20px;
         font-size: 16px;
         margin: 0 0 15px 0;
         font-family: "changa" !important; 
         font-weight: normal;
 
         a {
             font-family: "changa";
             font-weight: normal;
             span{
         font-family: "changa" !important; 
 
 
             }
         }
 
         b {
             font-weight: bold;
             font-family: "changa";
             font-size: 20px;
         }
 
         &.mb-5 {
             margin-bottom: 5px;
         }
     }
 }
 
 .new-orange-btn {
     background: #ff6d02;
     cursor: pointer;
     border: 0;
     color: white;
     height: 40px;
     line-height: 40px;
     font-size: 16px;
     border-radius: 12px;
     margin-right: 10px;
     text-align: center;
     padding: 0 30px;
     display: inline-block;
     text-decoration: none;
     font-family: "changa";
 }
 
 .new-stroke-btn {
     cursor: pointer;
     border: 1px solid #707070;
     background: transparent;
     color: white;
     height: 40px;
     line-height: 40px;
     font-size: 16px;
     border-radius: 12px;
     margin-right: 10px;
     text-align: center;
     padding: 0 40px;
     display: inline-block;
     text-decoration: none;
     font-family: "changa";
     @media(max-width:767px){
         padding: 0 20px;
     }
 }
 
 .cookie-btn-group {
     display: flex;
     margin-top: 20px;
     justify-content: center;
     margin-bottom: 20px;
 }
 
 .d-flex {
     display: flex;
 }
 
 .ml-auto {
     margin-left: auto;
 }
 
 .text-toggle-block {
     margin-bottom: 20px;
     position: relative;
     display: flex;
 
     .content-text-block {
         width: 70%;
 
         button {
             border: 0;
             background-color: transparent;
             outline: 0;
             color: white;
             padding: 0;
             font-family: "changa";
             font-size: 16px;
             font-weight: 700;
 
             cursor: pointer;
         }
     }
 
     .toggle-btn {
         width: calc(30% - 30px);
         // position: absolute;
         // right: 0;
         width: calc(30% - 30px);
         position: relative;
         @media(max-width:767px){
         width: calc(30% - 0px);
 
         }
     }
 }
 
 .arrow-down {
     width: 0;
     height: 0;
     border-left: 5px solid transparent;
     border-right: 5px solid transparent;
 
     border-top: 5px solid white;
 
     &.ml-5 {
         margin: 0 5px;
     }
 }
 
 .arrow-up {
     width: 0;
     height: 0;
     border-left: 5px solid transparent;
     border-right: 5px solid transparent;
     border-bottom: 5px solid white;
     margin-top: 2px;
 
     &.ml-5 {
         margin-left: 5px;
     }
 }
 
 .align-items-center {
     align-items: center;
 }
 
 p.mb-5 {
     margin-bottom: 5px;
 }
 
 p.mb-20 {
     margin-bottom: 20px;
 }
 
 .latest-cookie-block {
     margin-top: 100px;
     z-index: 1;
 }
 
 .dropdown-data {
     margin-top: -10px;
     margin-bottom: 20px;
 
     ul {
         padding: 0;
         margin: 0;
 
         li {
             list-style: none;
             width: calc(50% - 10px);
             margin-right: 10px;
             display: inline-block;
 
 
 
             @media(max-width:767px) {
                 width: calc(100%);
                 margin-right: 0;
             }
 
             .data-item {
                 height: 38px;
                 line-height: 38px;
                 text-align: center;
                 padding-left: 15px;
                 color: white;
                 border-radius: 10px;
                 margin-bottom: 10px;
                 background-color: #454545;
                 text-align: left;
                 position: relative;
                 width: 100%;
 
                 span {
                     font-family: "changa";
                     font-size: 14px;
                 }
 
                 .toggle-btn {
                     position: absolute;
                     top: 5px;
                     right: 10px;
 
                 }
 
             }
 
         }
     }
 }
 
 .toogle-custom-block {
     font-size: 14px;
     position: absolute;
     right: 0;
 
     .ToggleSwitch {
         background: #787880;
         // border: 1px solid purple;
         height: 28px;
         width: 52px;
         border-radius: 100px;
         cursor: pointer;
         position: relative;
 
         &.active {
             background-color: #ff6d02;
             color: #ff6d02;
         }
     }
 
     .ToggleSwitch .knob {
         position: relative;
         width: 24px;
         height: 24px;
         background: white;
         box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
         border: 1px solid #ddd;
         border-radius: 50%;
         left: 2px;
         transition: left 0.3s ease-out;
         top: 2px;
         transition: left 0.3s ease-out;
     }
 
     .toggle-text {
         position: absolute;
         left: -80px;
         white-space: nowrap;
         top: 0px;
         font-size: 16px;
         width: 70px;
         
         text-align: right;
         font-family: "changa";
     }
 
     .ToggleSwitch .knob.active {
         left: 26px;
     }
 
 }
 
 .app-body[dir="rtl"] {
     .toogle-custom-block .ToggleSwitch .knob.active {
         right: 26px;
         left: inherit;
     }
 }
 
 .app-body[dir="rtl"] {
     .content-text-block {
         .arrow-up.ml-5 {
             margin-left: 0;
             margin-right: 5px;
 
         }
     }
 
     .text-toggle-block .toggle-btn {
         width: calc(30% - 0px);
 
     }
 
     .latest-cookie-block {
 
 
 
         .toogle-custom-block {
             right: auto;
             left: 0;
             width: auto;
             white-space: nowrap;
 
             .toggle-text {
                 left:70px;
                 top: 0;
                 text-align: left !important;
                 line-height: 30px;
                 @media(max-width:767px){
                 left: 70px;
 
                 }
             }
 
         }
 
         .dropdown-data ul li {
             margin-right: 0;
             margin-left: 10px;
 
             .data-item {
                 padding-right: 10px;
                 text-align: right;
 
                 .toggle-btn {
                     left: 10px;
                     right: auto;
                 }
 
             }
         }
 
     }
 
 }
 
 .data-item .toggle-btn {
 
     .toogle-custom-block .toggle-text {
         top: -5px;
     }
 }
 
 .ad-class-mobile
 {
     
     text-align: center;
     margin: 20px auto;
 }
 
 .ad-class-desktop
 {
     text-align: center;
         margin: 20px auto;
 
 }
 .cookies-text {
  font-size: 11px;
  padding: 1px 0px;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4%;
}

.latest-cookie-block1 {
  display: flex;
  justify-content: space-between;
}

.cookies-texts {
 font-size: 14px;
 padding: 3px 33px;
 margin-top: -9%;
}
.cookies-textsar{
 font-size: 12px;
 padding: 3px 22px;
 margin-top: -9%;
 font-family: 'changa-Regular'
}

.cookies-text1 {
 padding: 3px 33px;
 font-size: 15px;
 font-family: 'changa-Regular'
}
.cookies-test{
 font-size: 12px;
 padding: 3px 22px;
 font-family: 'changa-Regular'
}

.termsofuse {
  font-size: 14px;
  margin-top: -30%;
  white-space: nowrap;
  font-family: 'changa-Regular';

  a {
    .link {
      color: white;
    }
  }
} 
.termsofusear {
 font-size: 11px;
 margin-top: -38%;
 white-space: nowrap;
 font-family: 'changa-Regular';
 margin-left: 0px;

 a {
   .link {
     color: white;
   }
 }
}
.popupcircle{
 left: 40%;
}

.newline{
 padding-left: 26px;
 padding-right: 26px;
}
.gdprage {
  font-size: 14px;
  margin-top: -25%;
  padding: 0px 25px 4px 19px;
  white-space: nowrap;
}
 .gdpragear{
   font-size: 11px;
   margin-top: -30%;
   padding: 0px 25px 4px 19px;
   white-space: nowrap;

 }
.cookie-btn-group1 {
 margin-top: -60px;
 margin-right: 4%;
}
.cookie-btn-group1ar{
 margin-top: -5em;
  margin-right:4%
}

.new-orange-btn1 {
 background: #ff6d02;
   cursor: pointer;
   border: 0;
   color: white;
   height: 38px;
   line-height: 1px;
   font-size: 16px;
   border-radius: 26px;
   margin-right: 8px;
   text-align: center;
   padding: 0 10px;
   display: inline-block;
   text-decoration: none;
   font-family: "changa";
   width: 178px;    
   margin-left: 134px
}
.new-orange-btn1ar {
 background: #ff6d02;
   cursor: pointer;
   border: 0;
   color: white;
   height: 38px;
   line-height: 1px;
   font-size: 16px;
   border-radius: 26px;
   margin-right: 8px;
   text-align: center;
   padding: 0 10px;
   display: inline-block;
   text-decoration: none;
   font-family: "changa";
   width: 178px;    
   margin-left: 16px;
   margin-right: 25px;

}

.new-stroke-btn1, .new-stroke-btn1ar{
  cursor: pointer;
  border: 1px solid #707070;
  background: transparent;
  color: #707070;
  height: 37px;
  line-height: 1px;
  font-size: 16px;
  border-radius: 26px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  font-family: "changa";
  width: 178px;



  &:hover {
    background-color: #ff6d02;
    color: white;
  }
}

.new-stroke-btn2 {
  cursor: pointer;
  border: 1px solid #707070;
  background: transparent;
  color: #707070;
  height: 37px;
  line-height: 1px;
  font-size: 16px;
  border-radius: 25px;
  text-align: center;
  padding: 0 40px;
  display: inline-block;
  text-decoration: none;
  font-family: "changa";
  width: 178px;
  padding: 0px 10px;
  white-space: nowrap;



  &:hover {
    background-color: #ff6d02;
    color: white;
  }
}
.new-stroke-btn2ar {
 cursor: pointer;
 border: 1px solid #707070;
 background: transparent;
 color: #707070;
 height: 37px;
 line-height: 40px;
 font-size: 16px;
 border-radius: 25px;
 text-align: center;
 padding: 0 40px;
 display: inline-block;
 text-decoration: none;
 font-family: "changa";
 width: 195px;
 padding: 0px 10px;
 white-space: nowrap;
 margin: 0px 14px 0px 0px; 

 &:hover {
   background-color: #ff6d02;
   color: white;
 }
}
.button-group{
 display: flex;
 // padding-right: 99px;
}
.button-groupar{
 display: flex;
 padding-right: 22px;

}

@media (max-width: 745px) {
  .latest-cookie-block {
    // text-align: left;
    // width: 800px;
    // margin: 0 auto;
    // padding: 0 30px;
    // max-height: 480px;
    // overflow-y: auto;

    /* Scrollbar Styling */
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #787880;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgba(255, 255, 255, .5);
    }

    @media (max-width: 1024px) {
        width: 100%;
    }

    p {
        line-height: 20px;
        font-size: 13px;
        margin: 0 0 15px 0;
        font-family: "changa" !important; 
        font-weight: normal;

        a {
            font-family: "changa";
            font-weight: normal;
            span{
        font-family: "changa" !important; 


            }
        }

        b {
            font-weight: bold;
            font-family: "changa";
            font-size: 20px;
        }

        &.mb-5 {
            margin-bottom: 5px;
        }
    }
}
 .latest-cookie-block1 {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
  
 }
 .row-container {   
   justify-content: space-between;
   align-items: center;
   margin-top: 36vh;
 }
 
 .cookies-texts {
   margin-top: -67%;
   font-family: "changa-regular" ;
   font-weight: normal;
   white-space: nowrap;
   top: 654px;
   left: 20px;
   width: 379px;
   height: 153px;
   letter-spacing: var(--unnamed-character-spacing-0);
   color: var(--unnamed-color-ffffff);
   text-align: left;
   letter-spacing: 0px;
   color: #FFFFFF;
   opacity: 1;
   margin-left: 8px;
   line-height: 20px;
   font-size: 13px;
 }
 .cookies-textsar{
   margin-top: -67%;
   font-family: "changa-regular" ;
   font-weight: normal;
   white-space: nowrap;
   top: 654px;
   left: 20px;
   width: 379px;
   height: 153px;
   letter-spacing: var(--unnamed-character-spacing-0);
   color: var(--unnamed-color-ffffff);
   text-align: left;
   letter-spacing: 0px;
   color: #FFFFFF;
   opacity: 1;
   margin-left: 33px;
   line-height: 20px;
   font-size: 13px;  

  }

 .termsofuse {
   font-size: 13px;
   margin: -169px 10px 0px 13px;
   font-family: 'changa-regular';
   font-weight: normal;
   white-space: nowrap;
   a {
     .link {
       color: white;
     }
   }     
 }
 .termsofusear {
   font-size: 13px;
   margin: -166px 2px 0px 19px;
   font-family: 'changa-regular';
   font-weight: normal;  
   white-space: nowrap; 
   a {
     .link {
       color: white;
     }
   }     
 }
 .gdprage {
   font-size: 13px;
   margin-top: -29%;
   font-family: 'changa-regular';
   font-weight: normal;
   white-space: nowrap;    
   margin: -61% 0% 0% -35%;

 }
 .gdpragear{
   font-size: 13px;
   margin-top: -40%;
   font-family: 'changa-regular';
   font-weight: normal;
   white-space: nowrap;
   margin: -66% -46% 0% 0%;;

 }
 .popupcircle{
   height: 17px;
   width: 16px;
  background-color: #ff6d02;
  border-radius: 50%;
  display: inline-block;
  left: -2%;
  position: relative;
  margin:-6px -7px -5px 10px;
 }
 .cookie-btn-group1 {
   display: flex;
   flex-direction: row;
   justify-content: center;    
  
 } 
 .cookie-btn-group1ar{
   display: flex;
   flex-direction: row;
   justify-content: center;   
   
  }
 .new-orange-btn1 {
   background: #ff6d02;
   cursor: pointer;
   border: 0;
   color: #fff;
   height: 25px;
   line-height: 1px;
   white-space: nowrap;
   font-family: 'changa-regular';
   font-weight: normal;
   margin: -28% 142% 0% 97%;
   font-size: 12px;
   width: 74px;

 } 
 .new-orange-btn1ar {
   background: #ff6d02;
   cursor: pointer;
   border: 0;
   color: #fff;
   margin-top: -15%;
   font-size: 11px;
   width: 100px;
   line-height: 10px;
   white-space: nowrap;
   font-family: changa;
   margin: -26% 10% 10% 102%;
   text-decoration: none;
   font-family: changa-regular;
   font-weight: 400;
   white-space: nowrap;
   height: 28px;
 } 
.new-stroke-btn1{
 cursor: pointer;
 border: 1px solid #707070;
 background: transparent;
 color: #fff;
 height: 25px;
 line-height: 1px;
 white-space: nowrap;
 font-family: 'changa-regular';
 font-weight: normal;
 margin: -20% 87% 0% 94%;
 font-size: 12px;
 width: 103px;
&:hover {
 background-color: #ff6d02;
 color: white;
}
}
.new-stroke-btn1ar {  
 cursor: pointer;
 border: 1px solid #707070;
 background: transparent;
 color: #fff;
 height: 27px;
 line-height: 1px;
 white-space: nowrap;
 font-family: 'changa-regular';
 font-weight: normal;
 margin: -21% 18% 0% 106%;
 font-size: 11px;
 width: 117px;
&:hover {
 background-color: #ff6d02;
 color: white;
 }
}
  .new-stroke-btn2 {
   cursor: pointer;
   border: 1px solid #707070;
   background: transparent;
   color: #fff;
   height: 25px;
   line-height: 1px;
   white-space: nowrap;
   font-family: 'changa-regular';
   font-weight: normal;
   margin: -20% 72% 0% 96%;
   font-size: 12px;
   width: 107px;
   &:hover {
     background-color: #ff6d02;
     color: white;
   }
 }
 .new-stroke-btn2ar {
   height: 28px;
   cursor: pointer;
   border: 1px solid #707070;
   background: transparent;
   color: #fff;
   margin-top: -15%;
   font-size: 11px;
   width: 96%;
   line-height: 0;
   white-space: nowrap;
   font-family: changa;
   margin: -17% 10% 10% 93%;

   &:hover {
     background-color: #ff6d02;
     color: white;
   }
 }
}
.cookies-policy-container-v2 {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  color: white;
  font-size: 16px;
  padding: 1rem 2rem;
  font-family: 'changa-Regular', sans-serif;
  border-top: 2px solid #ff6d02;
}

.cookies-policy-containerAr-v2 {
  font-size: 10px;

}

.latest-cookie-block1-v2 {
  display: flex;
  gap: 3rem;
  align-items: flex-start;

}

.button-group-v2 {
  align-self: center;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.flexC {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.flexC img {
  margin: 6px 0;
}
.flexC > div > span > a {
  font-family: "changa-Regular", sans-serif;
}

.button-group-v2, .button-groupar-v2 {
  display: flex;
  gap: 0.5rem;
}

.button-group-v2 button, .button-groupar-v2 button { 
  border-radius: 2000px;
  border: 1px solid #9B9B9B;
  padding: 0.25rem 0.5rem;
  width: 10rem;
  font-family: "changa-Regular", sans-serif;
  font-weight: 500;
  background-color: #000000;
  color: white !important;
  font-size: 16px;
  cursor: pointer;
}

.button-group-v2 button:hover, .button-groupar-v2 button:hover {
  background-color: #ff6d02;
}

.button-groupar-v2 {
  flex: 1;
  justify-content: flex-end;
}

.button-groupar-v2 button {
  font-size: 12px;
}

.button-group-v2 > div:first-child button, .button-groupar-v2 > div:first-child button {
  background-color: #ff6d02;
  border: 1px solid #ff6d02;
}

@media screen and (max-width: 900px) {

  .cookies-policy-container-v2 { 
    font-size: 14px;
    line-height: 135%;
  }

  .latest-cookie-block1-v2 {
    flex-direction: column;
    gap: 0.5rem;
  }

  .latest-cookie-block1-v2 > div:first-child {
    margin-bottom: 0.5rem;
  }

  .flexC img {
    margin: 0 0;
  }

  .button-group-v2, .button-groupar-v2 {
    justify-content: space-between;
    width: 100%;
  }

  .button-group-v2 button, .button-groupar-v2 button { 
    width: unset;
    min-width: 6rem;
    max-width:8rem;
    font-size: 10px;

   }

   .button-groupar-v2 button {
    font-size: 8px;

   }
}

@media screen and (min-width: 300px) and (max-width: 900px) {
  .button-group-v2, .button-groupar-v2 {
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
    gap: 0.5rem;
  }
  .button-group-v2 > div > button {
    font-size: 11px;
    margin-left: -4px;
  }
  .button-groupar-v2 div > button {
    font-size: 9px;
    margin-right: -2px;
  }
}

@media screen and (min-width: 1024px) {
  .cookies-texts-v2, .cookies-text1-v2,.cookies-textsar-v2 ,.cookies-test-v2{
    display: inline;

  }
  .latest-cookie-block1-v2 {
    gap: 0.5rem;
    margin: 0 10px;
  }
  .latest-cookie-block1-v2 > .cookies-texts-block-v2 {
    width: 38rem;
  }
  .latest-cookie-block1-v2 > .cookies-textsar-block-v2 {
    width: 45rem;
    font-size: 12px;
  }
  .termsofusear-v2,.gdpragear-v2 {
    font-size: 12px;
  }
  .latest-cookie-block1-v2 > div:nth-child(2) {
    margin-right: 10px;
  }
  .cookies-policy-container-v2 {
    font-size: 14px;
  }
}


// ==============================================

@media screen and (min-width: 1200px) and (max-width: 1400px) {
 .latest-cookie-block1 {
   display: flex;
    margin-top: 4px;
   justify-content: space-between;
 } 
.cookies-texts {
 font-size: 12px;
 padding: 3px 23px;
//  margin-top: -7%; 
white-space: nowrap
} 
.termsofuse {
 font-size: 12px;
 margin-top: -13%;
 white-space: nowrap;

 a {
   .link {
     color: white;
   }
 }
}
.gdprage {
 font-size: 12px;
 margin-top: -19%;
 padding: 0px 25px 4px 19px;
 white-space: nowrap;
}
.cookie-btn-group1 {
 margin-top: -3%;
 margin-right:1%;
padding: 20px 20px 20px 20px;
}
.new-orange-btn1 {
 background: #ff6d02;
 cursor: pointer;
 border: 0;
 color: white;
 width: 158px;
 height: 40px;
 margin: -26% 11% 14% -15%;
 line-height: 13px;
 font-size: 10px;
 border-radius: 21px;
 display: inline-block;
 text-decoration: none;
 font-family: "changa" !important;
 font-weight: normal;
}
.new-stroke-btn1 {
cursor: pointer;
border: 1px solid #707070;
background: transparent;
color: #707070;
height: 40px;
line-height: 1px;
font-size: 10px;
border-radius: 26px;
text-align: center;
display: inline-block;
text-decoration: none;
font-family: "changa";
width: 158px;
margin-top: -8%;

&:hover {
 background-color: #ff6d02;
 color: white;
}
}
.new-stroke-btn2 {
cursor: pointer;
border: 1px solid #707070;
background: transparent;
color: #707070;
height: 40px;
line-height: 40px;
font-size: 10px;
border-radius: 25px;
text-align: center;
padding: 0 40px;
display: inline-block;
text-decoration: none;
font-family: "changa";
width: 182px;
padding: 0px 10px;
margin-top: -8%;
&:hover {
 background-color: #ff6d02;
 color: white;
}
}

}
@media screen and  (min-width: 900px) and (max-width: 1900px){  
   .latest-cookie-block1 {
     display: flex;
      margin-top: 4px;
     justify-content: space-between;
   } 
 .cookies-texts {
   font-size: 11px; 
  white-space: nowrap;
 } 
 .cookies-textsar{
   font-size: 9px;
   font-family: 'changa-Regular'
  }
 .cookies-text1 {
   font-size: 11px;
   font-family: 'changa-Regular'
  }
  .cookies-test{
   font-size: 9px;
   font-family: 'changa-Regular'
  }

 .termsofuse {
   font-size: 11px;
   margin-top: -28%;
   white-space: nowrap;
 
   a {
     .link {
       color: white;
     }
   }
 }
 .termsofusear {
   font-size: 9px;
   margin-top: -36%;
   white-space: nowrap;
 
   a {
     .link {
       color: white;
     }
   }
 }
 .gdprage {
   font-size: 11px;
   margin-top: -21%;
   padding: 0px 25px 4px 19px;
   white-space: nowrap;
 }
 .gdpragear{
   font-size: 9px;
   margin-top: -27%;
   padding: 0px 25px 4px 19px;
   white-space: nowrap

 }
 .cookie-btn-group1 {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: -10%;
   margin-right: 18%;
   margin-left: -15%;;
}

.cookie-btn-group1ar{
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-top: -14%;
 margin-right: 3%;
}

.new-orange-btn1 {
   background: #ff6d02;
   cursor: pointer;
   border: 0;
   color: white;
   width: 120px;
   height: 30px;
   line-height: 13px;
   font-size: 12px;
   border-radius: 21px;
   text-decoration: none;
   font-family: "changa" !important;
   font-weight: normal;
   white-space: nowrap;
}
.new-orange-btn1ar {
 background: #ff6d02;
 cursor: pointer;
 border: 0;
 color: white;
 width: 120px;
 height: 30px;
 line-height: 13px;
 font-size: 10px;
 border-radius: 21px;
 text-decoration: none;
 font-family: "changa" !important;
 font-weight: normal;
 white-space: nowrap;
 margin-right: 5%;
}
.new-stroke-btn1,
.new-stroke-btn2 {
   border: 1px solid #707070;
   height: 30px;
   line-height: 1px;
   font-size: 12px;
   border-radius: 25px;
   width: 120px;
   white-space: nowrap;
}
.new-stroke-btn1ar, .new-stroke-btn2ar {
 border: 1px solid #707070;
 height: 30px;
 line-height: 1px;
 font-size: 10px;
 border-radius: 25px;
 width: 127px;
 white-space: nowrap;
 margin-right: 10px;
}

.new-stroke-btn1 {
 margin-right: -23%;
 white-space: nowrap;
}
 .new-stroke-btn2 {
   margin-right: -44%;
   white-space: nowrap;
}

.new-stroke-btn1:hover,
.new-stroke-btn2:hover {
   background-color: #ff6d02;
   color: white;
}


}

@media  (max-width:425px) {
  .cookies-policy-container-v2{
    padding: 1rem 0.6rem;
  }
}
@media (max-width:375px){
  .button-groupar-v2 div > button {
    font-size: 7px;
    margin-right: -5px;

  }
  .button-group-v2 button, .button-groupar-v2 button{
    padding: 0.15rem 0.3rem;

  }
  .cookies-policy-containerAr-v2 {
    padding: 1rem 0.1rem;
}
.cookies-policy-container-v2 {
  padding: 1rem 0.4rem;
}
}
@media screen and (min-width:1300px) and (max-width:1600px){
  .cookies-policy-container-v2 { 
    font-size: 13px;
    line-height: 130%;
  }
  .button-group-v2 button{
    font-size: 13px;
  }
  .button-groupar-v2 button{
    font-size: 12px;
  }
  .latest-cookie-block1-v2 > .cookies-texts-block-v2{
    width: 31rem;;
  }
  .button-group-v2, .button-groupar-v2{
    gap:0.2rem;
  }
  .cookies-policy-container-v2{
    padding: 1rem 0.3rem;
  }
  .flexC{
    align-items: center;
    justify-content: center;
  }
  .latest-cookie-block1-v2 > .cookies-textsar-block-v2{
    width: 34rem;
  }
}
 
 