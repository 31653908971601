/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

@import "app/resources/styles/variables.scss";

.quality-selector {
  display: block;
  background-color: #000;
  font-size: 0.8em;
  font-weight: 400;
  color: #fff;
  text-align: right;
  position: absolute;
  z-index: 1;
  pointer-events: all;
  .quality-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2px 10px;
    .hd-text {
      color: #f00;
      margin-left: 5%;
    }
    .radio-button {
      width: 10px;
      height: 10px;
      background-color: #383838;
      border-radius: 50%;
      margin: 0 5px;
      &.checked {
        background-color: #fff;
      }
    }
  }
}

.quality-selector::after {
  z-index: -1;
  background: #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
}
