/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .forgot-password .close-btn {
  right: auto;
  left: 20px; }

.app-body[dir="rtl"] .forgot-password .forgot-password-container .email-input img {
  right: auto;
  left: 15px; }

.forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0F1822;
  background-size: cover;
  padding: 70px 0;
  color: #fff;
  position: relative;
  min-height: calc(100vh - 185px - 80px);
  font-family: "changa"; }
  .forgot-password input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    color: #646464;
    margin: 0;
    border-radius: 5px;
    border: 2px solid #4a4a4a;
    background-color: #0F1822;
    color: #ffffff; }
  .forgot-password .error-message {
    color: #bd0000;
    font-style: normal;
    padding: 4px;
    margin-top: 0;
    margin-bottom: 6px;
    font-weight: 400;
    height: 17px;
    font-size: 12px;
    position: relative;
    align-items: baseline;
    text-align: left;
    width: 100%; }
  .forgot-password .close-btn {
    display: flex;
    position: absolute;
    right: 22px;
    top: 14px;
    background: transparent; }
  .forgot-password .forgot-password-container {
    width: 30%;
    min-width: 376px; }
    .forgot-password .forgot-password-container .forgot-password-title {
      font-size: 24px;
      height: 65px;
      line-height: 65px;
      width: 100%;
      white-space: nowrap;
      color: #ff740f; }
      .forgot-password .forgot-password-container .forgot-password-title p {
        margin: 0;
        text-align: center;
        color: #fff; }
    .forgot-password .forgot-password-container .description {
      width: 100%;
      height: auto;
      line-height: 28px;
      margin-top: 36px;
      font-size: 20px;
      margin-bottom: 26px;
      color: #4e4e4e; }
      .forgot-password .forgot-password-container .description p {
        margin: 0; }
    .forgot-password .forgot-password-container .description.informative {
      font-size: 20px;
      max-width: 880px;
      text-align: center; }
      .forgot-password .forgot-password-container .description.informative p {
        padding-bottom: 25px; }
    .forgot-password .forgot-password-container .email-input {
      position: relative; }
      .forgot-password .forgot-password-container .email-input img {
        position: absolute;
        right: 15px;
        top: 14px;
        z-index: 1; }
    .forgot-password .forgot-password-container .forgot-password-buttons {
      margin-top: 36px; }
      .forgot-password .forgot-password-container .forgot-password-buttons .forgot-button {
        text-transform: none;
        width: 100%;
        height: 50px;
        background-color: #ebebeb;
        margin: 0 0 10px;
        color: #5f5f5f;
        font-size: 20px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #ff740f;
        color: #ffffff; }
        .forgot-password .forgot-password-container .forgot-password-buttons .forgot-button.return-button {
          background-color: #414141; }
    .forgot-password .forgot-password-container .forgot-password-buttons.informative {
      margin-top: 93px; }
    @media (max-width: 745px) {
      .forgot-password .forgot-password-container .forgot-password-title {
        font-size: 18px; }
      .forgot-password .forgot-password-container .description,
      .forgot-password .forgot-password-container .email-input {
        font-size: 16px; }
      .forgot-password .forgot-password-container .forgot-password-buttons .forgot-button {
        font-size: 16px; }
      .forgot-password .forgot-password-container .forgot-password-buttons.informative {
        margin-top: 50px; } }
    @media screen and (max-width: 745px) {
      .forgot-password .forgot-password-container {
        width: calc(100% - 20vw);
        min-width: auto; } }
  @media screen and (max-width: 745px) {
    .forgot-password {
      padding: 0;
      min-height: calc(100vh - 185px - 80px); } }

.forgot-password.arabic .forgot-password-container .description {
  text-align: right; }

.forgot-password.arabic .forgot-password-container .description.informative {
  text-align: center; }

.forgot-password.arabic .forgot-password-container .email-input {
  background-position: calc(0% + 11px) 13px;
  text-align: right;
  padding: 0 20px 0 37px; }
