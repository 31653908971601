/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .myaccount-container {
  font-family: "changa"; }
  .app-body[dir="rtl"] .myaccount-container .form-myaccount .row .inner-column .change-password,
  .app-body[dir="rtl"] .myaccount-container .form-myaccount .row .inner-column .verify {
    right: auto;
    left: 0; }
  .app-body[dir="rtl"] .myaccount-container .checkbox-container .checkbox-text {
    margin-left: 0;
    margin-right: 10px; }

@media only screen and (max-width: 1024px) {
  .app-body[dir="rtl"] .myaccount-container .form-myaccount .row .left-column {
    margin: 0 0 10px; } }

.app-body[dir="rtl"] .myaccount-container .react-tel-input .flag-dropdown {
  border-right: 2px solid #494949;
  border-left: none; }

.app-body[dir="rtl"] .react-tel-input .selected-flag .flag {
  margin-right: 5px; }

.myaccount-container {
  background-color: #0F1822;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 185px - 60px); }
  .myaccount-container .myaccount {
    width: calc(100% - 25vw);
    display: flex;
    flex-direction: column;
    font-family: "changa"; }
    .myaccount-container .myaccount .overlay-title {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      margin: 75px 0;
      overflow: auto;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none; }
      @media only screen and (max-width: 745px) {
        .myaccount-container .myaccount .overlay-title {
          font-size: 20px; } }
      .myaccount-container .myaccount .overlay-title ::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
        display: none; }
    .myaccount-container .myaccount .my-account-action-buttons {
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      padding: 15px 0;
      margin-bottom: 30px;
      order: 5; }
      .myaccount-container .myaccount .my-account-action-buttons .btn {
        padding: 5px 10px;
        border-radius: 5px;
        height: 50px; }
        @media only screen and (max-width: 745px) {
          .myaccount-container .myaccount .my-account-action-buttons .btn {
            margin-bottom: 10px;
            white-space: nowrap;
            font-size: 16px; } }
      .myaccount-container .myaccount .my-account-action-buttons .btn-device-pair {
        background: #ff740f;
        width: 400px;
        height: 53px;
        border-radius: 5px; }
    .myaccount-container .myaccount .my-account-buttons {
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      padding: 15px 0;
      border-top: 1px solid #929090;
      order: 3; }
      .myaccount-container .myaccount .my-account-buttons .btn {
        padding: 5px 10px;
        border-radius: 5px;
        height: 50px; }
        @media only screen and (max-width: 745px) {
          .myaccount-container .myaccount .my-account-buttons .btn {
            margin-bottom: 10px;
            white-space: nowrap;
            font-size: 16px; } }
      .myaccount-container .myaccount .my-account-buttons .btn-device-pair {
        background: #ff740f; }
      .myaccount-container .myaccount .my-account-buttons .btn-cancel {
        color: #7f8080;
        background-color: #fff; }
        .myaccount-container .myaccount .my-account-buttons .btn-cancel:hover {
          background: #fff; }
        @media only screen and (max-width: 745px) {
          .myaccount-container .myaccount .my-account-buttons .btn-cancel {
            font-size: 15px; } }
      .myaccount-container .myaccount .my-account-buttons .btn-sign-out-all:hover {
        background: rgba(158, 158, 158, 0.2); }
      @media only screen and (max-width: 745px) {
        .myaccount-container .myaccount .my-account-buttons {
          flex-direction: column;
          order: 4; } }
    .myaccount-container .myaccount .my-account-checkboxes {
      order: 4; }
      @media only screen and (max-width: 745px) {
        .myaccount-container .myaccount .my-account-checkboxes .checkbox-container {
          margin: 10px 0; } }
      @media only screen and (max-width: 745px) {
        .myaccount-container .myaccount .my-account-checkboxes {
          order: 3; } }
    .myaccount-container .myaccount .rodal-dialog {
      padding: 0; }
      @media only screen and (max-width: 745px) {
        .myaccount-container .myaccount .rodal-dialog {
          width: 90% !important; } }
    .myaccount-container .myaccount .signout-dialog-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .myaccount-container .myaccount .signout-dialog-content .content {
        padding: 24px;
        text-align: center; }
      .myaccount-container .myaccount .signout-dialog-content .dialog-title {
        color: #ff740f;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.005em;
        margin-bottom: 10px; }
      .myaccount-container .myaccount .signout-dialog-content .dialog-text {
        color: #606060; }
      .myaccount-container .myaccount .signout-dialog-content .actions {
        display: flex;
        height: 36px; }
      .myaccount-container .myaccount .signout-dialog-content .dialog-ok-btn {
        border: 1px solid #606060;
        width: 100%;
        text-transform: none;
        color: #606060;
        padding: 0;
        margin: 0 0 -17px;
        border-radius: 0;
        background: #fff;
        font-size: 16px;
        height: 36px; }
        .myaccount-container .myaccount .signout-dialog-content .dialog-ok-btn:hover {
          background: rgba(158, 158, 158, 0.2); }
    .myaccount-container .myaccount .email-conaitner {
      position: relative; }
  .myaccount-container .contact-us {
    color: #5d5d5d;
    font-size: 18px; }
    @media only screen and (max-width: 745px) {
      .myaccount-container .contact-us {
        font-size: 16px; } }
    .myaccount-container .contact-us .contact-us-link {
      color: #5d5d5d;
      text-decoration: underline;
      margin: 0 3px; }
      .myaccount-container .contact-us .contact-us-link::first-letter {
        text-transform: uppercase; }
    .myaccount-container .contact-us:visited {
      color: #5d5d5d; }
  .myaccount-container .form-myaccount {
    padding-bottom: 10px;
    order: 2; }
    .myaccount-container .form-myaccount .row {
      display: flex;
      flex-direction: column; }
      .myaccount-container .form-myaccount .row .left-column,
      .myaccount-container .form-myaccount .row .right-column {
        flex-basis: 50%;
        display: flex;
        flex-direction: column; }
        .myaccount-container .form-myaccount .row .left-column .label,
        .myaccount-container .form-myaccount .row .right-column .label {
          height: 19px;
          color: #5d5d5d;
          font-size: 18px;
          margin: 20px 0 5px; }
          @media only screen and (max-width: 745px) {
            .myaccount-container .form-myaccount .row .left-column .label,
            .myaccount-container .form-myaccount .row .right-column .label {
              font-size: 16px; } }
        @media only screen and (max-width: 745px) {
          .myaccount-container .form-myaccount .row .left-column,
          .myaccount-container .form-myaccount .row .right-column {
            flex-direction: column; } }
      .myaccount-container .form-myaccount .row .inner-column {
        flex-basis: 100%;
        margin: 5px 0; }
        .myaccount-container .form-myaccount .row .inner-column .select-box .select-box-scroll {
          padding: 0 20px; }
        .myaccount-container .form-myaccount .row .inner-column .mobile-input-container,
        .myaccount-container .form-myaccount .row .inner-column .password-container {
          position: relative; }
        .myaccount-container .form-myaccount .row .inner-column .react-datepicker-wrapper,
        .myaccount-container .form-myaccount .row .inner-column .react-datepicker__input-container {
          width: 100%; }
        .myaccount-container .form-myaccount .row .inner-column .change-password,
        .myaccount-container .form-myaccount .row .inner-column .verify {
          position: absolute;
          right: 0;
          top: 0;
          padding: 15px;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #5d5d5d;
          text-decoration: underline;
          text-align: center;
          white-space: nowrap;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none; }
          .myaccount-container .form-myaccount .row .inner-column .enabled.change-password,
          .myaccount-container .form-myaccount .row .inner-column .verify.enabled {
            cursor: pointer; }
        .myaccount-container .form-myaccount .row .inner-column .verify {
          visibility: hidden; }
          .myaccount-container .form-myaccount .row .inner-column .verify.show {
            visibility: visible;
            cursor: pointer; }
        .myaccount-container .form-myaccount .row .inner-column .error-text {
          color: #dc3545;
          font-size: 14px; }
        .myaccount-container .form-myaccount .row .inner-column input {
          width: 100%;
          height: 50px;
          padding: 0 10px;
          color: #646464;
          font-size: 16px;
          margin: 0;
          border-radius: 5px;
          border: 2px solid #4a4a4a;
          background-color: #0F1822;
          color: #ffffff; }
          .myaccount-container .form-myaccount .row .inner-column input.mobile-input {
            display: none; }
          .myaccount-container .form-myaccount .row .inner-column input.error {
            background-color: #dc3545;
            color: #fff; }
            .myaccount-container .form-myaccount .row .inner-column input.error::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #fff;
              opacity: 1;
              /* Firefox */ }
            .myaccount-container .form-myaccount .row .inner-column input.error:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #fff; }
            .myaccount-container .form-myaccount .row .inner-column input.error::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #fff; }
          .myaccount-container .form-myaccount .row .inner-column input[type="date"] {
            background: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png") 99% 50% no-repeat; }
          .myaccount-container .form-myaccount .row .inner-column input[type="date"]::-webkit-inner-spin-button {
            display: none; }
          .myaccount-container .form-myaccount .row .inner-column input[type="date"]::-webkit-calendar-picker-indicator {
            opacity: 0; }
        .myaccount-container .form-myaccount .row .inner-column .phone-input {
          padding: 0 10px;
          border-radius: 5px;
          border: 2px solid #4a4a4a; }
          .myaccount-container .form-myaccount .row .inner-column .phone-input input {
            border: none;
            background-color: transparent; }
        .myaccount-container .form-myaccount .row .inner-column .select-box-container {
          width: 100%;
          margin: 0;
          border: 2px solid #4a4a4a;
          border-radius: 5px; }
          .myaccount-container .form-myaccount .row .inner-column .select-box-container .select-box {
            width: 100%;
            background: black; }
          .myaccount-container .form-myaccount .row .inner-column .select-box-container .selected {
            border-radius: 5px;
            line-height: 50px;
            height: 50px;
            color: white;
            background-color: #0F1822; }
          .myaccount-container .form-myaccount .row .inner-column .select-box-container select-box .select-box-elements {
            top: 50px; }
  .myaccount-container .mange-account-btn,
  .myaccount-container .mange-devices-btn,
  .myaccount-container .my-account-btn {
    border: 2px solid transparent;
    text-transform: uppercase; }
    .myaccount-container .mange-account-btn.selected,
    .myaccount-container .mange-devices-btn.selected,
    .myaccount-container .my-account-btn.selected {
      border-radius: 5px;
      border: 2px solid #ff6d02;
      color: #ff6d02; }
    .myaccount-container .mange-account-btn:hover,
    .myaccount-container .mange-devices-btn:hover,
    .myaccount-container .my-account-btn:hover {
      background: transparent; }
    @media screen and (max-width: 745px) {
      .myaccount-container .mange-account-btn,
      .myaccount-container .mange-devices-btn,
      .myaccount-container .my-account-btn {
        font-size: 16px; } }
  .myaccount-container .change-password-link {
    text-decoration: underline;
    cursor: pointer;
    color: #4470ca; }
  .myaccount-container .react-tel-input .flag-dropdown {
    background-color: #000000;
    border: 2px solid #494949;
    border-radius: 3px 3px;
    border-right: none;
    border-left: 2px solid #494949; }
  .myaccount-container .react-tel-input .flag-dropdown:hover .selected-flag {
    background-color: #000; }

/*Device width pixel lesser than 1024*/
@media only screen and (max-width: 1024px) {
  .myaccount-container .myaccount {
    width: calc(100% - 15vw); }
  .myaccount-container .form-myaccount .row {
    flex-direction: column; }
    .myaccount-container .form-myaccount .row .left-column {
      margin-right: 0;
      margin-bottom: 10px; } }
