/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .first-row .swiper-pagination-bullets {
  bottom: 10px; }

.smart-tv-banner {
  margin-top: 122px; }
  .smart-tv-banner .first-row {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 4px;
    width: 100%;
    justify-content: center; }
    .smart-tv-banner .first-row .swiper-touch-container {
      width: 100%;
      background-color: #000000; }
  .smart-tv-banner .swiper-container {
    margin-top: 0;
    overflow: hidden;
    max-width: 80%;
    margin: 0 10%; }
    @media (max-width: 745px) {
      .smart-tv-banner .swiper-container {
        padding: 0;
        max-width: 100%;
        margin: 0; } }
    .smart-tv-banner .swiper-container .customized-swiper-pagination .swiper-pagination-bullet {
      margin: 0 4px;
      width: 28px;
      height: 3px;
      padding: 0;
      border-radius: 0;
      background-color: #fff; }
    .smart-tv-banner .swiper-container .customized-swiper-pagination .swiper-pagination-bullet-active {
      background: #f9710b;
      opacity: 1; }
    .smart-tv-banner .swiper-container .subscribe-thumbnail {
      cursor: pointer; }
    .smart-tv-banner .swiper-container .image-thumbnail .image-container {
      text-align: center; }
      .smart-tv-banner .swiper-container .image-thumbnail .image-container img {
        width: 100%;
        height: auto;
        border-radius: 5px; }
  @media (max-width: 745px) {
    .smart-tv-banner {
      width: 100%;
      overflow: hidden;
      justify-content: center; } }
