/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
#loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #ff6d02;
  width: 50px;
  height: 50px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite; }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.app-body[dir="rtl"] .enter-otp-container .enter-mobile2 {
  text-align: right; }

.app-body[dir="rtl"] .enter-otp-container .resend-code {
  text-align: right; }

.app-body[dir="rtl"] .enter-otp-container .conditions-container {
  text-align: right; }

.enter-otp-container {
  width: 100%;
  min-height: calc(100vh - 185px - 80px);
  padding: 0 10%;
  text-align: center;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "changa"; }
  @media (max-width: 745px) {
    .enter-otp-container {
      min-height: calc(100vh - 185px - 80px); } }
  .enter-otp-container .margin-collapse {
    height: 1px; }
  .enter-otp-container .enjoy_weyyak_experience {
    margin-top: 70px;
    font-size: 20px;
    line-height: 21px; }
    @media (max-width: 745px) {
      .enter-otp-container .enjoy_weyyak_experience {
        font-size: 16px; } }
  .enter-otp-container .enter-mobile2 {
    margin-top: 60px;
    padding: 0 10px;
    line-height: 21px;
    font-size: 18px;
    text-align: left;
    width: 100%; }
    @media (max-width: 745px) {
      .enter-otp-container .enter-mobile2 {
        font-size: 12px; } }
  .enter-otp-container .input-container {
    margin-top: 10px;
    width: 100%;
    display: flex; }
    .enter-otp-container .input-container .input {
      width: 100%;
      height: 54px;
      border-radius: 5px;
      border: 2px solid #4a4a4a;
      padding: 0 20px;
      font-size: 18px; }
      @media (max-width: 745px) {
        .enter-otp-container .input-container .input {
          font-size: 16px; } }
      .enter-otp-container .input-container .input::placeholder {
        color: #5d5d5d; }
    .enter-otp-container .input-container .country-code {
      width: 17%;
      min-width: 60px; }
    .enter-otp-container .input-container .mobile-number {
      width: 52%; }
  .enter-otp-container .resend-code {
    width: 100%;
    height: 18px;
    margin: 10px 0;
    padding: 0 10px;
    font-size: 14px;
    text-align: left; }
    .enter-otp-container .resend-code .resend-text {
      color: #ffffff;
      text-decoration: underline; }
      .enter-otp-container .resend-code .resend-text.active {
        cursor: pointer; }
    .enter-otp-container .resend-code .timer {
      color: #5d5d5d;
      margin: 0 5px; }
    @media (max-width: 745px) {
      .enter-otp-container .resend-code {
        font-size: 12px; } }
  .enter-otp-container .subscribe-button {
    width: 400px;
    height: 54px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #ff6d02;
    min-width: 75px; }
    @media (max-width: 745px) {
      .enter-otp-container .subscribe-button {
        width: 258px;
        height: 42px; } }
    .enter-otp-container .subscribe-button span {
      font-size: 18px; }
      @media (max-width: 745px) {
        .enter-otp-container .subscribe-button span {
          font-size: 16px; } }
  .enter-otp-container .exit {
    height: 22px;
    font-size: 18px;
    text-decoration: underline;
    margin-top: 40px;
    cursor: pointer; }
  .enter-otp-container .conditions-container {
    width: 100%;
    margin: 90px 0 80px;
    color: #555555;
    text-align: left;
    font-size: 15px;
    line-height: 23px; }
    @media (max-width: 745px) {
      .enter-otp-container .conditions-container {
        font-size: 12px;
        line-height: 16px; } }
    .enter-otp-container .conditions-container .by-clicking,
    .enter-otp-container .conditions-container .free-for {
      font-weight: 700; }

.transaction-done-container {
  width: 100%;
  min-height: calc(100vh - 185px - 80px);
  padding: 0 10px;
  text-align: center;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 745px) {
    .transaction-done-container {
      min-height: calc(100vh - 185px - 80px); } }
  .transaction-done-container .message {
    margin-top: 70px;
    font-size: 22px;
    line-height: 29px; }
    @media (max-width: 745px) {
      .transaction-done-container .message {
        font-size: 16px;
        line-height: 20px; } }
  .transaction-done-container .button {
    margin: 100px 0;
    width: 400px;
    height: 53px;
    border-radius: 5px;
    background-color: #ff6d02;
    font-size: 18px; }
    @media (max-width: 745px) {
      .transaction-done-container .button {
        width: 250px;
        font-size: 16px; } }
