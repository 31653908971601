/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .forgot-password-mobile .close-btn {
  right: auto;
  left: 20px; }

.app-body[dir="rtl"] .forgot-password-mobile .forgot-password-container .email-input img {
  right: auto;
  left: 15px; }

.forgot-password-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-size: cover;
  padding: 70px 0;
  color: #fff;
  position: relative;
  padding: 50px 0;
  min-height: calc(100vh - 185px - 80px); }
  .forgot-password-mobile .close-btn {
    display: flex;
    position: absolute;
    right: 22px;
    top: 14px;
    background: transparent; }
  .forgot-password-mobile .forgot-password-container {
    width: 30%;
    min-width: 376px; }
    .forgot-password-mobile .forgot-password-container .forgot-password-title {
      font-size: 24px;
      height: 65px;
      line-height: 65px;
      width: 100%;
      white-space: nowrap;
      color: #4e4e4e; }
      .forgot-password-mobile .forgot-password-container .forgot-password-title p {
        margin: 0;
        text-align: center;
        color: #fff; }
      @media (max-width: 745px) {
        .forgot-password-mobile .forgot-password-container .forgot-password-title {
          font-size: 18px; } }
    .forgot-password-mobile .forgot-password-container .description {
      width: 100%;
      font-size: 21px;
      height: auto;
      line-height: 28px;
      margin-top: 36px;
      margin-bottom: 26px;
      color: #4e4e4e; }
      .forgot-password-mobile .forgot-password-container .description p {
        margin: 0; }
      @media (max-width: 745px) {
        .forgot-password-mobile .forgot-password-container .description {
          font-size: 16px; } }
    .forgot-password-mobile .forgot-password-container .description.informative {
      max-width: 880px;
      text-align: center; }
      .forgot-password-mobile .forgot-password-container .description.informative p {
        padding-bottom: 25px; }
    .forgot-password-mobile .forgot-password-container .email-input {
      position: relative; }
      .forgot-password-mobile .forgot-password-container .email-input img {
        position: absolute;
        right: 15px;
        top: 14px; }
      .forgot-password-mobile .forgot-password-container .email-input input {
        margin: 0;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border: none;
        padding: 0 37px 0 20px;
        color: #646464;
        background-color: #ebebeb;
        border-radius: 5px;
        caret-color: #4e4e4e; }
        @media (max-width: 745px) {
          .forgot-password-mobile .forgot-password-container .email-input input {
            font-size: 16px; } }
    .forgot-password-mobile .forgot-password-container .forgot-password-buttons {
      margin-top: 36px; }
      .forgot-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button {
        text-transform: none;
        width: 100%;
        height: 50px;
        background-color: #ebebeb;
        margin: 0 0 10px;
        color: #5f5f5f;
        cursor: pointer;
        border-radius: 5px;
        font-size: 20px; }
        @media (max-width: 745px) {
          .forgot-password-mobile .forgot-password-container .forgot-password-buttons .forgot-button {
            font-size: 16px; } }
      .forgot-password-mobile .forgot-password-container .forgot-password-buttons .highlight {
        background-color: #ff740f;
        color: #ffffff; }
    .forgot-password-mobile .forgot-password-container .forgot-password-buttons.informative {
      margin-top: 93px; }
    @media screen and (max-width: 745px) {
      .forgot-password-mobile .forgot-password-container {
        width: 80%;
        min-width: auto; } }
  @media screen and (max-width: 745px) {
    .forgot-password-mobile {
      padding: 30px 0;
      min-height: calc(100vh - 185px - 80px); } }

.forgot-password.arabic .forgot-password-container .description {
  text-align: right; }

.forgot-password.arabic .forgot-password-container .description.informative {
  text-align: center; }

.forgot-password.arabic .forgot-password-container .email-input {
  background-position: calc(0% + 11px) 13px;
  text-align: right;
  padding: 0 20px 0 37px; }
