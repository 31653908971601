/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.social-login-container {
  width: 399px; }
  .social-login-container .login-facebook,
  .social-login-container .login-apple,
  .social-login-container .login-twitter {
    display: block;
    min-width: 250px;
    width: 100%;
    height: 55px;
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
    text-align: left;
    cursor: pointer; }
    .social-login-container .login-facebook .button-container,
    .social-login-container .login-apple .button-container,
    .social-login-container .login-twitter .button-container {
      min-width: 300px;
      width: 100%;
      height: 100%;
      padding: 0 15px;
      position: relative;
      display: flex; }
      .social-login-container .login-facebook .button-container .facebook-icon,
      .social-login-container .login-apple .button-container .facebook-icon,
      .social-login-container .login-twitter .button-container .facebook-icon {
        margin-top: 13px;
        width: 30px;
        height: 30px;
        background-color: #ffffff; }
      .social-login-container .login-facebook .button-container .twitter-icon,
      .social-login-container .login-apple .button-container .twitter-icon,
      .social-login-container .login-twitter .button-container .twitter-icon {
        margin-top: 14px;
        width: 30px;
        height: 27px; }
      .social-login-container .login-facebook .button-container .apple-icon,
      .social-login-container .login-apple .button-container .apple-icon,
      .social-login-container .login-twitter .button-container .apple-icon {
        margin-top: 10px;
        width: 35px;
        height: 35px;
        background-color: #f6f3ed; }
    .social-login-container .login-facebook .social-login-text,
    .social-login-container .login-apple .social-login-text,
    .social-login-container .login-twitter .social-login-text {
      width: 80%;
      display: inline-block;
      height: 55px;
      line-height: 55px;
      text-align: center; }
      @media (max-width: 1024px) {
        .social-login-container .login-facebook .social-login-text,
        .social-login-container .login-apple .social-login-text,
        .social-login-container .login-twitter .social-login-text {
          margin: 0 30px;
          white-space: nowrap; } }
      @media (max-width: 745px) {
        .social-login-container .login-facebook .social-login-text,
        .social-login-container .login-apple .social-login-text,
        .social-login-container .login-twitter .social-login-text {
          font-size: 16px; } }
    .social-login-container .login-facebook .apple-login-text,
    .social-login-container .login-apple .apple-login-text,
    .social-login-container .login-twitter .apple-login-text {
      color: black;
      width: 80%;
      display: inline-block;
      height: 55px;
      line-height: 55px;
      text-align: center; }
      @media (max-width: 1024px) {
        .social-login-container .login-facebook .apple-login-text,
        .social-login-container .login-apple .apple-login-text,
        .social-login-container .login-twitter .apple-login-text {
          margin: 0 30px;
          white-space: nowrap; } }
      @media (max-width: 745px) {
        .social-login-container .login-facebook .apple-login-text,
        .social-login-container .login-apple .apple-login-text,
        .social-login-container .login-twitter .apple-login-text {
          font-size: 16px; } }
  .social-login-container .login-facebook {
    margin-top: 20px;
    margin-bottom: 28px;
    background-color: #3e4e8d; }
    @media (max-width: 1024px) {
      .social-login-container .login-facebook {
        margin-bottom: 10px; } }
  .social-login-container .login-twitter {
    margin-top: 50px;
    background-color: #25afff; }
    @media (max-width: 1024px) {
      .social-login-container .login-twitter {
        margin-top: 0; } }
  @media (max-width: 1024px) {
    .social-login-container {
      width: 100%; } }
  .social-login-container .login-apple {
    margin-top: 20px;
    background-color: #f6f3ed; }
    @media (max-width: 1024px) {
      .social-login-container .login-apple {
        margin-top: 0px; } }

.app-body[dir="rtl"] .signup-container .sign-up .login-facebook .social-login-text,
.app-body[dir="rtl"] .signup-container .sign-up .login-apple .social-login-text,
.app-body[dir="rtl"] .signup-container .sign-up .login-twitter .social-login-text {
  margin: 0 40px 0 0; }
  @media (max-width: 1024px) {
    .app-body[dir="rtl"] .signup-container .sign-up .login-facebook .social-login-text,
    .app-body[dir="rtl"] .signup-container .sign-up .login-apple .social-login-text,
    .app-body[dir="rtl"] .signup-container .sign-up .login-twitter .social-login-text {
      margin: 0 20px 0 0; } }
